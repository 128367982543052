import { IonToast, IonTextarea, IonIcon, IonList, IonLoading, IonItem, IonLabel, IonSelect, IonSelectOption, IonInput, IonToggle } from '@ionic/react';
import Drawer from '@material-ui/core/Drawer';
import { arrowBackCircle, arrowForwardOutline, backspace, caretDownCircle, caretDownOutline, caretUpCircle, caretUpOutline, checkmark, checkmarkCircle, checkmarkSharp, closeCircle, create, createSharp, documentSharp, documentLockOutline, globeOutline, helpCircle, helpCircleOutline, helpCircleSharp, helpSharp, peopleOutline, reload, removeSharp, trashSharp, chevronDown, chevronUp, warning, folderSharp, createOutline, text, pricetag, folder, folderOutline, pricetagOutline, ellipseOutline, earthOutline, ellipsisHorizontalOutline, trashOutline, openOutline, saveOutline, camera, personCircle, reader, location, briefcase, school, documentAttach, chatbubble, informationCircle, informationCircleOutline } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { WithContext as ReactTags } from 'react-tag-input';
import { FileAccess, FileType, User, UserDTO, UserFile } from '../../types/User';
import UserAPI from '../../apis/User';
import AvailabilityAPI from '../../apis/Availability';
import Logo from '../../images/icon.svg';
import Utilities from '../../Utilities';
import { getLastSharedDate, getRecentUniqueEvents, hasUndeletedEvents } from '../../stores/Event';
import { Event } from '../../types/Event';
import { Plugins } from '@capacitor/core';
import OTPInput, { ResendOTP } from "otp-input-react";
import CurveArrow from '../../images/curve-arrow.png';

import SectorSkillManager from './Settings/SectorSkillManager';
// import '../../styles/Components/ProfileProgress.scss';
// import '../../styles/Components/TagManager.scss';
import { useHistory } from "react-router-dom";
import Confetti from 'react-confetti'
import { useTour } from '@reactour/tour'
import Configuration from '../../Configuration';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useFilePicker } from 'use-file-picker';
import { isValidUrl } from "is-youtube-url";
import FileListItem from './Files/FileListItem';

import TypeDocument from '../../images/TypeDocument.png'
import TypeLink from '../../images/TypeLink.png';
import TypeVideoLink from '../../images/TypeVideoLink.png';

import Progress0 from '../../images/progress/progress-0-min.png';
import Progress10 from '../../images/progress/progress-10-min.png';
import Progress20 from '../../images/progress/progress-20-min.png';
import Progress30 from '../../images/progress/progress-30-min.png';
import Progress40 from '../../images/progress/progress-40-min.png';
import Progress50 from '../../images/progress/progress-50-min.png';
import Progress60 from '../../images/progress/progress-60-min.png';
import Progress70 from '../../images/progress/progress-70-min.png';
import Progress80 from '../../images/progress/progress-80-min.png';
import Progress90 from '../../images/progress/progress-90-min.png';
import Progress100 from '../../images/progress/progress-100-min.png';
import InternalTracker from '../../InternalTracker';
import InlineSelect from './InlineSelect';
import { Keyboard } from '@capacitor/keyboard';
import SkillManagerEnhanced from '../SkillManagerEnhanced';

const axios = require('axios');
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];
var mime = require('mime-types')

const PROGRESS = [
    Progress0,
    Progress10,
    Progress20,
    Progress30,
    Progress40,
    Progress50,
    Progress60,
    Progress70,
    Progress80,
    Progress90,
    Progress100
]

const REMINDER_SCHEDULE = [
    1,
    2,
    4,
    7,
    10,
    14,
    21,
    30
]

const SUGGESTIONS = {
    DEGREES: [
        "Higher National Diploma (HND)",
        "Bachelor of Arts (BA)",
        "Bachelor of Science (BSc)",
        "Bachelor of Commerce (Bcom)",
        "Bachelor of Music (BMus)",
        "Law (LLB)",
        "Engineering (BEng)",
        "Master of Arts (MA)",
        "Master of Science (MSc)",
        "Postgraduate Certificate (PGCE)",
        "PhD",
        "History"
    ],
    GRADES: [
        "Pass",
        "Fail",
        "Distinction",
        "Merit",
        "1st",
        "2:1",
        "2:2",
        "3rd"
    ],
    SCHOOLS: [
        'Abertay University',
        'Aberystwyth University',
        'Academy of Contemporary Music',
        'Accrington and Rossendale College',
        'Amsterdam Fashion Academy',
        'Anglia Ruskin University',
        'Anglo European College of Chiropractic',
        'Arden University',
        'Arts University Bournemouth',
        'ARU London',
        'Askham Bryan College',
        'Aston University',
        'Bangor University',
        'Barking and Dagenham College',
        'Bath Spa University',
        'Berkshire College of Agriculture',
        'Bicton College',
        'BIMM',
        'Birkbeck, University of London',
        'Birmingham City University',
        'Bishop Burton College',
        'Bishop Grosseteste University',
        'Blackburn College',
        'Blackpool and the Fylde College',
        'Bournemouth University',
        'BPP University',
        'Bradford College',
        'Bridgwater and Taunton College',
        'Bristol, University West of England, (UWE)',
        'British College of Osteopathic Medicine',
        'Brunel University London',
        'Buckinghamshire New University',
        'Bury College',
        'Camberwell College of Arts, University of the Arts London',
        'Cambridge School of Visual and Performing Arts',
        'Canterbury Christ Church University',
        'Cardiff Metropolitan University',
        'Cardiff University',
        'Central Saint Martins, University of the Arts London',
        'Chelsea College of Arts, University of the Arts London',
        'Norwich, City College',
        'Bristol, City College',
        'Liverpool College, City of',
        'City, University of London',
        'Cleveland College of Art and Design',
        'Cliff College',
        'Coleg Llandrillo',
        'Menai, Coleg',
        'Sir Gar, Coleg',
        'College of Agriculture, Food and Rural Enterprise',
        'West Anglia, College of',
        'Cornwall College',
        'Courtauld Institute of Art, University of London',
        'Coventry University',
        'De Montfort University',
        'Duchy College',
        'Durham University',
        'East Riding College',
        'Easton and Otley College',
        'Edge Hill University',
        'Edinburgh Napier University',
        'ESCP Europe Business School',
        'European School of Osteopathy',
        'Falmouth University',
        'Futureworks Manchester Media School',
        'Glasgow Caledonian University',
        'Glasgow School of Art',
        'Gloucestershire College',
        'Glyndwr University, Wrexham',
        'Goldsmiths, University of London',
        'London, GSM',
        'Guildford College',
        'Halesowen College',
        'Harper Adams University',
        'Havering College of Further and Higher Education',
        'Hereford College of Arts',
        'Heriot-Watt University',
        'Holy Cross College',
        'Hugh Baird College',
        'Hull College',
        'Hult International Business School',
        'Imperial College London',
        'Institute of Contemporary Music Performance',
        'Istituto Marangoni London',
        'Keele University',
        'Kensington College of Business',
        'King\'s College London, University of London',
        'Kingston College',
        'Kingston University',
        'Lakes College West Cumbria',
        'Lancaster University',
        'Leeds Beckett University',
        'Leeds City College',
        'Leeds College of Art',
        'Leeds College of Music',
        'Leeds Trinity University',
        'Lincoln College',
        'Liverpool Hope University',
        'Liverpool Institute for Performing Arts',
        'Liverpool John Moores University',
        'London College of Communication, University of the Arts London',
        'London College of Fashion, University of the Arts London',
        'London College, UCK',
        'London Metropolitan University',
        'London School of Business and Management',
        'London School of Economics and Political Science, University of London',
        'London South Bank University',
        'Loughborough College',
        'Loughborough University',
        'Manchester College',
        'Manchester Metropolitan University',
        'Middlesex University',
        'Milton Keynes College',
        'Mont Rose College of Management and Sciences',
        'Moulton College',
        'Myerscough College',
        'Nazarene Theological College',
        'Durham, New College',
        'New College of the Humanities',
        'Newcastle College',
        'Newcastle University',
        'Newham College London',
        'Newman University',
        'Norland College Limited',
        'North Lindsey College',
        'Northbrook College Sussex',
        'Northumbria University',
        'Norwich University of the Arts',
        'Nottingham Trent University',
        'NPTC Group',
        'Oxford Brookes University',
        'Pearson College London (including Escape Studios)',
        'Peter Symonds College',
        'Plumpton College',
        'Plymouth College of Art',
        'Saint Mark and Saint John, University of',
        'Plymouth University',
        'Point Blank Ltd',
        'Queen Margaret University, Edinburgh',
        'Queen Mary University of London',
        'Queen\'s University Belfast',
        'Ravensbourne',
        'Regent\'s University London',
        'Richmond, The American International University in London',
        'Robert Gordon University',
        'Rose Bruford College',
        'Rotherham College of Arts and Technology',
        'Royal Academy of Dance',
        'Royal Academy of Music, University of London',
        'Royal Agricultural University',
        'Royal Central School of Speech and Drama',
        'Royal College of Music',
        'Royal Conservatoire of Scotland',
        'Royal Holloway, University of London',
        'Royal Northern College of Music',
        'Royal Veterinary College, University of London',
        'Royal Welsh College of Music and Drama',
        'Ruskin College',
        'Sandwell College of Further and Higher Education',
        'Selby College',
        'Sheffield College',
        'Sheffield Hallam University',
        'SOAS, University of London',
        'Solihull College',
        'South Essex College',
        'South Thames College',
        'Southampton Solent University',
        'Sparsholt College, Hampshire',
        'Spurgeon\'s College',
        'SRUC Scotland’s Rural College',
        'Saint George\'s, University of London',
        'Twickenham, Saint Mary\'s University',
        'Staffordshire University',
        'Stockport College',
        'Stranmillis University College',
        'Sussex Coast College Hastings',
        'Swansea University',
        'Teesside University',
        'The London Institute of Banking & Finance (formerly ifs University College)',
        'University of Law',
        'Manchester, The University of',
        'Trinity Laban Conservatoire of Music and Dance',
        'Truro and Penwith College',
        'UCL (University College London)',
        'Ulster, University of',
        'University Campus Barnsley',
        'Oldham, University Campus',
        'University Centre Colchester at Colchester Institute',
        'Croydon, University Centre',
        'Farnborough, University Centre',
        'Grimsby, University Centre',
        'University Centre Hartpury',
        'Peterborough, University Centre',
        'University Centre Reaseheath',
        'St Helens, University Centre',
        'Birmingham, University College',
        'University College of Estate Management',
        'University for the Creative Arts',
        'Aberdeen, University of',
        'Bath, University of',
        'Bedfordshire, University of',
        'Birmingham, University of',
        'Bolton, University of',
        'Bradford, University of',
        'Brighton, University of',
        'Bristol, University of',
        'Buckingham, University of',
        'Cambridge, University of',
        'Central Lancashire, University of',
        'Chester, University of',
        'Chichester, University of',
        'Cumbria, University of',
        'Derby, University of',
        'Dundee, University of',
        'East Anglia, University of',
        'East London, University of',
        'Edinburgh, University of',
        'Essex, University of',
        'Exeter, University of',
        'Glasgow, University of',
        'Gloucestershire, University of',
        'Greenwich, University of',
        'Hertfordshire, University of',
        'Huddersfield, University of',
        'Hull, University of',
        'Kent, University of',
        'Leeds, University of',
        'Leicester, University of',
        'Lincoln, University of',
        'Liverpool, University of',
        'University of London Institute in Paris',
        'Northampton, University of',
        'Nottingham, University of',
        'Oxford, University of',
        'Portsmouth, University of',
        'Reading, University of',
        'Roehampton, University of',
        'Salford, University of',
        'Sheffield, University of',
        'South Wales, University of',
        'Southampton, University of',
        'Saint Andrews, University of',
        'Stirling, University of',
        'Strathclyde, University of',
        'University of Suffolk',
        'Sunderland, University of',
        'Surrey, University of',
        'Sussex, University of',
        'Highlands and Islands, University of',
        'West of Scotland, University of the',
        'University of Wales Trinity Saint David',
        'Warwick, University of',
        'West London, University of',
        'Westminster, University of',
        'Winchester, University of',
        'Wolverhampton, University of',
        'Worcester, University of',
        'York, University of',
        'Wakefield College',
        'Warwickshire College',
        'Wimbledon College of Arts, University of the Arts London',
        'Wirral Metropolitan College',
        'Writtle University College',
        'York College',
        'York Saint John University'
    ],
    FIELDS: [
        "Accounting",
        "Aerospace engineering",
        "African studies",
        "Agricultural sciences",
        "Agriculture",
        "American studies",
        "Anatomy, physiology & pathology",
        "Ancient language studies",
        "Animal science",
        "Anthropology",
        "Archaeology",
        "Architecture",
        "Architecture, Building and Planning",
        "Astronomy",
        "Aural; oral sciences",
        "Biological Sciences",
        "Biology",
        "Biotechnology",
        "Botany",
        "Building",
        "Business and Administrative Studies",
        "Business studies",
        "Celtic studies",
        "Ceramics; glass",
        "Chemical, process; energy engineering",
        "Chemistry",
        "Chinese studies",
        "Cinematics; photography",
        "Civil engineering",
        "Classical Greek studies",
        "Clinical dentistry",
        "Comparative literary studies",
        "Complementary medicines, therapies; well-being",
        "Computer generated visual; audio effects",
        "Computer science",
        "Computer Sciences",
        "Crafts",
        "Creative Arts and Design",
        "Dance",
        "Design studies",
        "Development studies",
        "Drama",
        "Eastern, Asiatic, African, American and Australasian Languages, Literature and Related Subjects",
        "Economics",
        "Education",
        "Electronic; electrical engineering",
        "Engineering",
        "English studies",
        "European Languages, Literature and Related Subjects",
        "European Studies",
        "Finance",
        "Fine art",
        "Food; beverage studies",
        "Forensic; archaeological sciences",
        "Forestry; arboriculture",
        "French studies",
        "Games",
        "General engineering",
        "Genetics",
        "Geology",
        "German studies",
        "Health informatics",
        "Heritage studies",
        "Historical and Philosophical Studies",
        "History by area",
        "History by period",
        "History by topic",
        "Hospitality, leisure, sport, tourism; transport",
        "Human; social geography",
        "Human resource management",
        "Imaginative writing",
        "Information services",
        "Information systems",
        "Italian studies",
        "Japanese studies",
        "Journalism",
        "Landscape; garden design",
        "Latin studies",
        "Law",
        "Law by area",
        "Law by topic",
        "Linguistics",
        "Linguistics, Classics and Related Subjects",
        "Management studies",
        "Maritime technology",
        "Marketing",
        "Mass Communication and Documentation",
        "Materials science",
        "Materials technology not otherwise specified",
        "Mathematical Sciences",
        "Mathematics",
        "Mechanical engineering",
        "Media studies",
        "Medical technology",
        "Medicine and Dentistry",
        "Metallurgy",
        "Microbiology",
        "Minerals technology",
        "Modern Middle Eastern studies",
        "Molecular biology, biophysics; biochemistry (C700)",
        "Music",
        "Naval architecture",
        "Nursing",
        "Nutrition",
        "Office skills",
        "Operational research",
        "Ophthalmics",
        "Other Asian studies",
        "Pharmacology, toxicology; pharmacy",
        "Philosophy",
        "Physical geographical sciences",
        "Physical Sciences",
        "Physics",
        "Planning (urban, rural; regional)",
        "Politics",
        "Polymers; textiles",
        "Portuguese studies",
        "Pre-clinical dentistry",
        "Production; manufacturing engineering",
        "Psychology",
        "Publicity studies",
        "Publishing",
        "Research; study skills in education",
        "Russian; East European studies",
        "Scandinavian studies",
        "Science of aquatic; terrestrial environments",
        "Social policy",
        "Social Studies",
        "Social work",
        "Sociology",
        "Software engineering",
        "South Asian studies",
        "Spanish studies",
        "Sport; exercise science",
        "Statistics",
        "Subjects Allied to Medicine",
        "Technologies",
        "Theology; religious studies",
        "Training teachers",
        "Veterinary Sciences, Agriculture and Related Subjects",
        "Zoology"
    ]
}

interface ProfileProgressProps {
    explicitOpen?: boolean;
    noRemindLaterSet?: boolean;
    autoSelectSection?: string;
    onClosed?: () => void;
    onNumberOfUncompletedUpdate?: (number) => void;
    onAvailabilityConfidenceUpdate?: (boolean) => void;
    section?: number;
    triggerUpdate?: boolean;
    onImageUpdate? : () => void;
    hideNavigation?: boolean;
}

export interface Task {
    softSkipped?: boolean;
    id: string;
    title: string;
    description: string;
    descriptionSub?: string;
    completed: boolean;
    descriptionCompleted?: string;
    descriptionFirst?: string;
    color?: string;
    subId?: string;
}

export interface Tag {
    id: string,
    text: string,
    icon?: any;
    color?: string;
}

const ProfileProgress: React.FC<ProfileProgressProps> = ({ noRemindLaterSet, explicitOpen, autoSelectSection, section, onClosed, onNumberOfUncompletedUpdate, triggerUpdate, onAvailabilityConfidenceUpdate, onImageUpdate, hideNavigation }) => {

    const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } = useTour()
    const [successToast, setSuccessToast] = useState <string> (null) // Success toast message
    const [errorToast, setErrorToast] = useState <string> (null) // Error toast message
    const [open, setOpen] = useState <boolean> (false); 
    const [confetti, setConfetti] = useState <boolean> (false); 
    const [taskList, setTaskList] = useState <Task[]> ([]); 
    const [taskListOneCollapsed, setTaskListOneCollapsed] = useState <boolean> (false);
    const [taskListOneAllCompleted, setTaskListOneAllCompleted] = useState <boolean> (false);
    const [taskListTwoCollapsed, setTaskListTwoCollapsed] = useState <boolean> (false);
    const [taskListTwoAllCompleted, setTaskListTwoAllCompleted] = useState <boolean> (false);
    const [nextStepId, setNextStepId] = useState <string> (null); 
    const [nextOpenCycleI, setNextOpenCycleI] = useState <number> (null); 
    const [user, setUser] = useState <User> (null); 
    const [loadingMsg, setLoadingMsg] = useState <string> (null); 
    const [currentSelection, setCurrentSelection] = useState <Task> (null);
    const [currentSMSSetupStep, setCurrentSMSSetupStep] = useState <number> (0);
    const [completedSteps, setCompletedSteps] = useState <number> (0)
    const [totalSteps, setTotalSteps] = useState <number> (0)
    const [qualificationSuggestionQuery, setQualificationSuggestionQuery] = useState <string> ("");
    const [fileCategorySuggestionsQuery, setFileCategorySuggestionsQuery] = useState <string> ("");
    const [editedQualificationI, setEditedQualificationI] = useState <number> (null);
    const [editedFileI, setEditedFileI] = useState <number> (null);
    const [editedFolderI, setEditedFolderI] = useState <number> (null);
    const [focusedField, setFocusedField] = useState <string> (null);
    const [keyboardOpen, setKeyboardOpen] = useState <boolean> (false) // Whether the keyboard is showing
    const [typing, setTyping] = useState <boolean> (false);
    const [otpCode, setOtpCode] = useState <string> ("");
    const [orderingFiles, setOrderingFiles] = useState <boolean> (false);
    const [selectedFolderId, setSelectedFolderId] = useState <number> (null);
    const [showFiles, setShowFiles] = useState <boolean> (null); // todo remove
    const [userCategories, setUserCategories] = useState <string[]> (null);

    const [hideDrawerForIonComponents, setHideDrawerForIonComponents] = useState <boolean> (null) // Hiding drawer for loading, and ion selector
    const [lastUpdatedCalendar, setLastUpdatedCalendar] = useState <Date | null> (null) // Last time an event has been added to the calendar
    const [lastSycnedDate, setLastSycnedDate] = useState <Date | null> (null) // Last time the user triggered a sync
    const [lastConfirmedAvailability, setLastConfirmedAvailability] = useState <Date | null> (null) // Last time the user confirmed availability
    const [howAvailabilityConfidenceScoreCalcShowing, setHowAvailabilityConfidenceScoreCalcShowing] = useState <boolean> (false) // Whether to show how we calcualte confidence score
    const [availabilityConfidencePercentage, setAvailabilityConfidencePercentage] = useState <number> (100) // Confidence in availability
    const [notSetIndustriesYet, setNotSetIndustriesYet] = useState <boolean> (false) // True if this is the first the the user is looking at the industries
    const history = useHistory();
    const [openFileSelector, { filesContent, loading, plainFiles, clear }] = useFilePicker({
        // readFilesContent: false,
        readAs: 'BinaryString',
        limitFilesConfig: { min: 1, max: 1 }
    });

    useEffect(() => {
        async function onFileSelect() {
            if (plainFiles && plainFiles[0]) {
                let newFiles = user.files || [];
                let item = newFiles[0];
                item.fileName = plainFiles[0].name.split(".").shift();
                item.fileFormat = plainFiles[0].name.split(".").pop().toLowerCase();
                if (item.fileFormat === "png" || item.fileFormat === "jpg" || item.fileFormat === "jpeg") {
                    item.thumbnailImg = await Utilities.resizeAndRotateImage(plainFiles[0] as Blob)
                }
                newFiles[0] = item;
                setUser({ ...user, files: newFiles });
            }
        }
        onFileSelect();
    }, [plainFiles])


    useEffect(() => {
        if ((window as any).os !== "web") {
            Keyboard.addListener('keyboardWillShow', (info) => {
                setTimeout(() => {
                    setKeyboardOpen(true)
                })
            });

            Keyboard.addListener('keyboardWillHide', () => {
                setKeyboardOpen(false)
            });
        }

        if (!noRemindLaterSet) 
            compileList();

        adjustLayout();
        setTimeout(() => { adjustLayout(); }, 700);
        setTimeout(() => { adjustLayout(); }, 1000);
        setTimeout(() => { adjustLayout(); }, 1600);
        setTimeout(() => { adjustLayout(); }, 2400);
        setTimeout(() => { adjustLayout(); }, 3200);
    }, [])

    useEffect(() => {
        console.log(currentSelection)
        if (currentSelection && currentSelection.id === 'smsnotifications') {
            let user: User = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) as User : null;
            if (!user.phoneNumber) {
                setCurrentSMSSetupStep(1);
            } else if (user.phoneNumber && !user.phoneNumberVerified) {
                setCurrentSMSSetupStep(2);
            } else {
                setCurrentSMSSetupStep(3)
            }
        }
    }, [currentSelection])

    const adjustLayout = function() {
        if ((window as any).notchTop || (window as any).notchBottom) {
            let drawerWrapper: HTMLElement = document.querySelector('.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorBottom');
            if (drawerWrapper) {
                drawerWrapper.style.maxHeight = 'calc(100% - 40px - ' + (((window as any).notchTop) + ((window as any).notchBottom)) + 'px)'
            }
        }
    }

    useEffect(() => {
        if (triggerUpdate) {
            compileList();
        }
    }, [triggerUpdate])

    useEffect(() => {
        let body = document.getElementsByTagName("body");
        if (body && body[0]) {
            if (loadingMsg || hideDrawerForIonComponents) {
                body[0].classList.add("backdrop-no-scroll");
                setTimeout(() => {
                    body[0].classList.add("backdrop-no-scroll");
                }, 150)
            } else {
                body[0].classList.remove("backdrop-no-scroll");
                setTimeout(() => {
                    body[0].classList.remove("backdrop-no-scroll");
                }, 150)
            }
        }
    }, [loadingMsg, hideDrawerForIonComponents])

    useEffect(() => {
        let body = document.getElementsByTagName("body");
        if (body && body[0]) {
          body[0].classList.remove("backdrop-no-scroll");
        }
        if (section) {
            setTimeout(() => {
                let el = document.getElementById("todo-" + section + "-wrapper");
                if (el)
                    el.scrollIntoView({ behavior: "smooth", block: "start" });
            }, 400)
        }
    }, [open, section]);

    useEffect(() => {
        setKeyboardOpen(false);
        compileList();
    }, [explicitOpen])

    const reloadLocalData = function(autoSelectSection, keepOpen?, onFinish?) {

        setLoadingMsg("Updating your progress...");

        setTimeout(() => {

            UserAPI
                .getNew()
                .then(data => {
                    // @ts-ignore
                    let user = data.results;
                    if (user) {
                        let oldUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {};
                        localStorage.setItem("user", JSON.stringify({...oldUser, ...user}))
                    } else {
                        if (!autoSelectSection) {
                            (window as any).toast("Failed to fetch your profile", "error")
                        }
                    }
                    if (hideNavigation) {
                        onClosed();
                    }
                    setLoadingMsg(null);
                    compileList(true, true);
                    // if (!keepOpen) {
                    //     // alert("Close")
                    //     setCurrentSelection(null);
                    //     if (autoSelectSection) {
                    //         onClosed();
                    //     }
                    // }
                    if (onFinish) {
                        onFinish(user);
                    }
                }).catch(e => {
                    (window as any).toast("Failed to fetch your profile " + JSON.stringify(e), "error")
                })

        }, 1);

    }

    const compileList = async function(confettiOnComplete?: boolean, jumpToNextUncompleted?: boolean) {

        console.log("@@ COMPILE list started")

        if (window.location.pathname.startsWith("/sharefile")) {
            setOpen(false);
            return;
        }

        let haveEvent = await hasUndeletedEvents();
        let haveContact = (localStorage.getItem("haveContact") === "true");
        let hirersCount = localStorage.getItem("contactsList") ? (JSON.parse(localStorage.getItem("contactsList")).length) : 0;
        let agenciesCount = localStorage.getItem("agenciesList") ? (JSON.parse(localStorage.getItem("agenciesList")).length) : 0;

        // Won't show if haven't added contacts or events as those have a different blocking guide component
        // if ((!haveEvent || !haveContact) && !explicitOpen) {
        //     setOpen(false);
        //     return;
        // }

        let user: User = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) as User : null;
        let contactsCount = parseInt(localStorage.getItem("contactsCount") || "0") || 0;

        if (!user && (window as any).Cypress === undefined) {
            setOpen(false);
            return;
        }

        if (!user) {
            user = {};
        }

        let tasks: Task[] = [
            {
                id: "share",
                title: "Share your availability",
                description: "Add your first event",
                descriptionCompleted: "First event has been added",
                completed: haveEvent
            },
            {
                id: "contacts",
                title: "Add contacts",
                description: "Find & add contacts to share with",
                descriptionCompleted: "First contact has been added, you can add more any time",
                completed: contactsCount !== 0, //  hirersCount !== 0
            },
            {
                id: "agencies",
                title: "Add agencies",
                description: "Add and confirm the agencies that represent you",
                descriptionCompleted: "First agency has been added, you can add more any time",
                completed: agenciesCount !== 0
            },
            {
                id: "image",
                title: "Upload an Image",
                description: "Upload a profile image that contacts recognise",
                descriptionCompleted: "Profile image has been uploaded",
                completed: user.userSetProfileImage
            },
            {
                id: "headline",
                title: "Your Headline",
                description: "Explain what you do in one sentence",
                descriptionCompleted: "Headline has been saved",
                completed: user.headline !== undefined && user.headline !== null && user.headline !== "",
            },
            {
                id: "postalcode",
                title: "Zip/Postcode",
                description: "Set your normal Zip/Postcode and your maximum travel distance",
                descriptionCompleted: "Zip/Postcode has been saved",
                completed: user.postalCode !== null && user.postalCode !== undefined && user.postalCode !== "" // todo all
            },
            {
                id: "sectorssubsectors",
                title: "Your sectors & skills",
                description: "Add core skills then select suggested Sectors & Subsectors",
                descriptionFirst: "",
                descriptionCompleted: "Sectors & skills have been saved",
                completed: (user && user.workerAttributes && user.workerAttributes.length > 0) // user.industries && user.industries.length !== 0 && user.industries.filter(item => item.subSectors && item.subSectors.length !== 0).length !== 0
            },
            {
                id: "qualifications",
                title: "Qualifications",
                description: "Your Qualifications",
                descriptionCompleted: "Qualifications has been saved",
                completed: user.qualifications && user.qualifications.length !== 0
            },
            localStorage.getItem("FeatureFiles") ? {
                id: "files",
                title: "Show What I Do",
                description: "Add examples of your work files to showcase your expertise",
                descriptionCompleted: "Add examples of your work files to showcase your expertise",
                completed: user.files && user.files.length !== 0,
            } : null,
            {
                id: "smsnotifications",
                title: "SMS Instant text offer alerts",
                description: "SMS Instant text offer alerts",
                descriptionCompleted: "SMS Instant text offer alerts",
                completed: user.phoneNumber && user.phoneNumberVerified,
            },
            {
                id: "verify",
                title: "",
                description: "To be verified you must have your image, your headlines & be connected with two or more verified employers/hirers, or a verified recruitment agency who view your availability.",
                descriptionCompleted: "Your account has been verified",
                completed: (user.verified || user.verifiedByAgency)
            },
        ]

        tasks = tasks.filter(task => task);

        const signUpDate = new Date(user.createdAt);
        // if signed up in past 7 days don't show verified status, as new users cannot do anything more, once completing the full onboarding
        if (signUpDate && Utilities.daysDiffBetween(signUpDate, new Date()) < 7) {
            tasks = tasks.filter(item => item.id !== "verify");
        }

        let completedStepsCount = tasks.filter(item => item.completed).length;
        let lastTasksCompletedCount = localStorage.getItem("lastTasksCompleted") ? parseInt(localStorage.getItem("lastTasksCompleted")) : 0;

        if (onNumberOfUncompletedUpdate) {
            onNumberOfUncompletedUpdate(tasks.length - completedStepsCount);
        }

        let completedLetsGetStartedSteps = tasks.filter((item, i) => i < 3).filter(item => item.completed).length
        if (completedLetsGetStartedSteps === tasks.filter((item, i) => i < 3).length) {
            setTaskListOneCollapsed(true);
            setTaskListOneAllCompleted(true)
        } else {
            setTaskListOneCollapsed(false);
            setTaskListOneAllCompleted(false)
        }

        let completedProfileSteps = tasks.filter((item, i) => i > 2).filter(item => item.completed).length
        if (completedProfileSteps === tasks.filter((item, i) => i > 2).length) {
            setTaskListTwoCollapsed(true);
            setTaskListTwoAllCompleted(true);
        } else {
            setTaskListTwoCollapsed(false);
            setTaskListTwoAllCompleted(false)
        }

        const stepsLeftOtherThanVerify = tasks.filter((item, i) => i > 1 && i < tasks.length - 1).filter(item => !item.completed && item.id !== "verify").length
        if (!localStorage.getItem("adminVerificationRequestSent") && stepsLeftOtherThanVerify === 0) {
            localStorage.setItem("adminVerificationRequestSent", "true");
            UserAPI.sendVerificationRequest(user.id);
            InternalTracker.trackEvent("Profile Completed")
        }

        let lastUpdatedCalendar = (await getLastSharedDate()) || null // user.lastTimelineUpdateAt || null
        if (lastUpdatedCalendar) {
            setLastUpdatedCalendar(new Date(lastUpdatedCalendar))
        }
        let lastSycnedDate = localStorage.getItem("lastSync")
        if (lastSycnedDate && haveContact) {
            if (lastUpdatedCalendar) {
                console.log(new Date(lastSycnedDate), new Date(lastUpdatedCalendar), new Date(lastSycnedDate) < new Date(lastUpdatedCalendar))
                setLastSycnedDate(new Date(lastSycnedDate) > new Date(lastUpdatedCalendar) ? new Date(lastSycnedDate) : new Date(lastUpdatedCalendar) );
            } else {
                setLastSycnedDate(new Date(lastSycnedDate));
            }
        }
        if (user.availabilityLastConfirmed) {
            setLastConfirmedAvailability(new Date(user.availabilityLastConfirmed))
        }

        if (confettiOnComplete && completedStepsCount > lastTasksCompletedCount) {
            setConfetti(true)
        }

        setCompletedSteps(completedStepsCount);
        setTotalSteps(tasks.length);

        const hoursBetweenLastAvailabilityUpdate =  lastUpdatedCalendar ? Utilities.hoursDiffBetween(new Date(lastUpdatedCalendar), new Date()) : null;
        const hoursBetweenLastAvailabilityConfirm = (user.availabilityLastConfirmed && new Date(user.availabilityLastConfirmed)) ? Utilities.hoursDiffBetween(new Date(user.availabilityLastConfirmed), new Date()) : null;
    
        const hoursBetweenLastAvailabilityConfidenceAction = 
            (hoursBetweenLastAvailabilityUpdate !== null && hoursBetweenLastAvailabilityConfirm !== null) ?
            (hoursBetweenLastAvailabilityUpdate < hoursBetweenLastAvailabilityConfirm ? hoursBetweenLastAvailabilityUpdate : hoursBetweenLastAvailabilityConfirm) :
                (hoursBetweenLastAvailabilityUpdate || hoursBetweenLastAvailabilityConfirm || null);
    
        let availabilityConfidencePercentage: number = hoursBetweenLastAvailabilityConfidenceAction ? (hoursBetweenLastAvailabilityConfidenceAction < 24 ? 100 : hoursBetweenLastAvailabilityConfidenceAction > 240 ? 0 : Math.ceil(100 - (hoursBetweenLastAvailabilityConfidenceAction / 240 * 100))) : 0;
        if (!tasks[0]?.completed) availabilityConfidencePercentage = 0;
        setAvailabilityConfidencePercentage(availabilityConfidencePercentage);
    
        if (onAvailabilityConfidenceUpdate)
            onAvailabilityConfidenceUpdate(availabilityConfidencePercentage ? true : false);

        localStorage.setItem("guideHiddenForever", "true");

        let tasksObj = {};
        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];
            const taskId = task.id;
            tasksObj["p_" + (taskId === "image" ? "im" : taskId === "smsnotifications" ? "sm" : taskId === "share" ? "ev" : taskId === "contacts" ? "co" : taskId === "headline" ? "he" : taskId === "postalcode" ? "lo" : taskId === "sectorssubsectors" ? "se" : taskId === "qualifications" ? "qu" : taskId === "files" ? "fi" : taskId === "verify" ? "ve" : taskId)] = task.completed ? 1 : 0;
        }

        let miscMetadata = JSON.stringify({
            ...tasksObj,
            u_ca: user ? Utilities.formatDate(new Date(user.createdAt), "YYYY-MM-DD") : "null",
            c_hh: localStorage.getItem("hirersCount") && parseInt(localStorage.getItem("hirersCount")) > 0 ? 1 : 0,
            c_ha: localStorage.getItem("agenciesCount") && parseInt(localStorage.getItem("agenciesCount")) > 0 ? 1: 0,
            c_ch: localStorage.getItem("hirersCount") ? parseInt(localStorage.getItem("hirersCount")) : 0,
            c_ca: localStorage.getItem("agenciesCount") ? parseInt(localStorage.getItem("agenciesCount")) : 0,
        });

        miscMetadata = miscMetadata.replace(/"/g, '').replace(/,/g, ';').replace("{", "").replace("}", "");
        localStorage.setItem("miscMetadata", miscMetadata);

        // if ((window as any).openreplayTracker) {
        //     (window as any).openreplayTracker.setMetadata('misc', miscMetadata);
        // }

        if (localStorage.getItem("guideHiddenForever") && !explicitOpen) {
            setOpen(false);
            return;
        }

        if (completedStepsCount === tasks.length && !explicitOpen) {
            setOpen(false);
            return;
        }

        let nextStep = tasks.find(item => !item.completed);
        if (nextStep) {
            setNextStepId(nextStep.id)
        }
        setTaskList(tasks);
        user.industries = user.industries || [];
        user.qualifications = user.qualifications || [];
        user.qualifications = user.qualifications.map(item => {
            if (item.endDate) {
                item.endYear = item.endDate.split("-")[0]
                item.endMonth = item.endDate.split("-")[1]
            }
            return item;
        })
        setNotSetIndustriesYet(user.industries.length === 0)

        user.files = (user.files || []).sort(Utilities.dynamicSort("displayOrder"));
        setUserCategories([...new Set(user.files.map(item => item.fileCategoryName))]);
        setUser(user)
        setOpen(true)

        if (autoSelectSection && tasks.find(item => item.id === autoSelectSection)) {
            setCurrentSelection(tasks.find(item => item.id === autoSelectSection));
        }

        if (localStorage.getItem("userGuideOpenSection")) {
            setCurrentSelection(tasks.find(item => item.id === localStorage.getItem("userGuideOpenSection")));
            localStorage.removeItem("userGuideOpenSection");
        }

        const nextUncompletedJumpableTask = tasks.find(item => !item.completed && item.id !== "verify" && item.id !== "contacts" &&item.id !== "share");
        if (jumpToNextUncompleted && nextUncompletedJumpableTask) {
            setCurrentSelection(nextUncompletedJumpableTask);
        } else if (jumpToNextUncompleted) {
            setCurrentSelection(null);
            onClosed();
        }

        console.log("@@ COMPILE list finished")
    }

    const verifyOtpCode = async function(phoneNumber, code, autoSelectSection) {
        setLoadingMsg("Verifying Code...");
        UserAPI.verifyOtp(phoneNumber, code).then(res => {
            setLoadingMsg(null);
            // @ts-ignore
            if (!res.success) {
                // @ts-ignore
                (window as any).toast(res.message || "Failed to verify", "error");
                setOtpCode("");
            } else {
                InternalTracker.trackEvent("Phone Number Verified");
                (window as any).toast("Phone number verified", "success");
                setOpen(false);
                setCurrentSelection(null);
                setTimeout(() => {
                    reloadLocalData(autoSelectSection);
                })
            }
        }).catch(e => {
            setLoadingMsg(null);
            (window as any).toast("Failed to verify", "error")
        })
    }

    const sendOtpCode = async function(phoneNumber) {
        setOtpCode("");
        setLoadingMsg("Sending Verification SMS...");
        InternalTracker.trackEvent("Phone Number Updated");
        UserAPI.sendVerificationSMS(phoneNumber).then(res => {
            setLoadingMsg(null);
            setCurrentSMSSetupStep(2);
        }).catch(e => {
            setLoadingMsg(null);
            (window as any).toast("Failed to send verification SMS", "error")
        })
    }

    const updateQualification = async function(user: User, i: number, field: string, value: string) {
        if (field === "endMonth") {
            if (value && value.startsWith("0") && value.length > 1) {
                value = value.substr(1)
            }
            if (!value) {
                value = ""
            }
            switch(value.toLocaleLowerCase())  {
                case "jan": value = "1"; break;
                case "feb": value = "2"; break;
                case "mar": value = "3"; break;
                case "apr": value = "4"; break;
                case "may": value = "5"; break;
                case "jun": value = "6"; break;
                case "jul": value = "7"; break;
                case "aug": value = "8"; break;
                case "sep": value = "9"; break;
                case "oct": value = "10"; break;
                case "nov": value = "11"; break;
                case "dec": value = "12"; break;
            }
            if (value.toLocaleLowerCase() === "jan") {
                value = "1"
            }
        }
        let newQualifications = user.qualifications || [];
        newQualifications[i][field] = value
        setUser({ ...user, qualifications: newQualifications });
    }

    const haveUnsavedSkills = function() {
        let unsavedSkillsCount = 0;
        document.querySelectorAll(".ReactTags__tagInputField").forEach(item => {
            // @ts-ignore
            if (item.value && item.value.trim()) {
                unsavedSkillsCount++;
            }
        })
        return unsavedSkillsCount !== 0;
    }

    const close = async function(nextOpenCycleI?: number, hideForever?: boolean, forceSave? : boolean) {

        const oldUser = JSON.parse(localStorage.getItem("user"));
        
        if (haveUnsavedSkills()) {
            const unsavedFirstSuggestion = document.querySelector(".sector-skill-manager .suggestions>div:first-child") as HTMLElement;
            if (unsavedFirstSuggestion) {
                (window as any).onSkillAddFinalized = () => {
                    close(nextOpenCycleI, hideForever, true);
                    (window as any).onSkillAddFinalize = null;
                }
                unsavedFirstSuggestion.click();
                return;
            }
        }

        if (oldUser && user) {
            if (!oldUser.industries) {
                oldUser.industries = [];
            }
            if (!user.industries) {
                user.industries = [];
            }
            if (!user.qualifications) {
                user.qualifications = [];
            }
            if (!oldUser.qualifications) {
                oldUser.qualifications = [];
            }
            if (
                forceSave ||
                oldUser.headline !== user.headline || 
                oldUser.postalCode !== user.postalCode || 
                oldUser.maxDistance !== user.maxDistance || 
                (JSON.stringify(oldUser.industries) !== JSON.stringify(user.industries)) || 
                (JSON.stringify(oldUser.qualifications.map(uq => {
                    delete uq.endDateTimestamp;
                    delete uq.startDateTimestamp;
                    if (uq.endMonth && uq.endYear) {
                        uq.endDate = uq.endYear + "-" + uq.endMonth;
                    }
                    delete uq.endMonth
                    delete uq.endYear
                    if (uq.startMonth && uq.startYear) {
                        uq.startDate = uq.startYear + "-" + uq.startMonth;
                    }
                    delete uq.startMonth
                    delete uq.startYear
                    return uq;
                })) !== JSON.stringify(user.qualifications.map(uq => {
                    delete uq.endDateTimestamp;
                    delete uq.startDateTimestamp;
                    if (uq.endMonth && uq.endYear) {
                        uq.endDate = uq.endYear + "-" + uq.endMonth;
                    }
                    delete uq.endMonth
                    delete uq.endYear
                    if (uq.startMonth && uq.startYear) {
                        uq.startDate = uq.startYear + "-" + uq.startMonth;
                    }
                    delete uq.startMonth
                    delete uq.startYear
                    return uq;
                })))
            ) {
                const saveBtn = document.querySelector(".save-progress-btn:not(.no-auto-save)") as HTMLElement;
                if (saveBtn) {
                    saveBtn.click();
                    return;
                }
            }
        }

        if (explicitOpen || !nextOpenCycleI) {
            if (onClosed) {
                onClosed();
            }
        } else if (hideForever) {
            localStorage.setItem("guideHiddenForever", "true");
        } else {
            localStorage.setItem("lastOpenDate", new Date().toString());
            localStorage.setItem("lastOpenDateCycle", nextOpenCycleI.toString());
        }
        setOpen(false)
    }

    const orderFile = function(fileI, increment) {
        var newFiles = user.files;
        var oldIndex = fileI;
        var newIndex = !increment ? fileI + 1 : fileI - 1;
        var old = newFiles[oldIndex];
        newFiles[oldIndex] = newFiles[newIndex];
        newFiles[newIndex] = old;
        for (let i = 0; i < newFiles.length; i++) {
            newFiles[i].displayOrder = i+1;
        }
        setUser({ ...user, files: newFiles });
    }

    let totalTasks = taskList.length;
    let completedTasks = taskList.filter(item => item.completed).length;
    const editedFile = user && user.files && editedFileI !== null && user.files[editedFileI] ? user.files[editedFileI] : null;
    const editedFolder = user && user.folders && editedFolderI !== null && user.folders[editedFolderI] ? user.folders[editedFolderI] : null;

    let SECTION_HEADER = null;

    if (currentSelection) {
        SECTION_HEADER = (
            <React.Fragment>
                <div className='header-wrapper'>
                    { (autoSelectSection && !hideNavigation) &&
                        <div className="section-pagination">
                            { taskList.map((task, i) => {
                                const icon = task.id === "image" ? personCircle : task.id === "headline" ? reader : task.id === "postalcode" ? location : task.id === "sectorssubsectors" ? briefcase : task.id === "qualifications" ? school : task.id === "files" ? documentAttach : task.id === "smsnotifications" ? chatbubble : null;
                                if (!icon) {
                                    return null;
                                }

                                return (
                                    <div 
                                        onClick={() => {
                                            setCurrentSelection(task);
                                        }}
                                        data-current={task.id === currentSelection.id}
                                        data-completed={task.completed}
                                    >
                                        <IonIcon icon={icon} />
                                        { (taskList.length - 2) !== i &&
                                            <div className='arrow' data-reverse={i % 2 !== 0}>
                                                <img src={CurveArrow} />
                                            </div>
                                        }
                                    </div>
                                )
                            } )}
                            <div className='close'>
                                <IonIcon icon={closeCircle} onClick={() => {
                                    close()
                                }} />
                            </div>
                        </div>
                    }
                    <div className="header section-header">
                        {/* { !autoSelectSection && */}
                            {/* <div className="back-btn" onClick={() => {
                                if (autoSelectSection) {
                                    close()
                                } else {
                                    setCurrentSelection(null);
                                }
                            }}>
                                <IonIcon icon={arrowBackCircle} />
                            </div> */}
                        {/* } */}
                        <div
                            className="side"
                            style={ (!autoSelectSection) ? { } : {
                                padding: 0,
                                flexBasis: '100%'
                            } }
                        >
                            <h1>{currentSelection.title}</h1>
                            <h2>{ orderingFiles ? "You can change the order of files in which they appear for your contacts, and prospective contacts. Use the down and up arraow to move." : currentSelection.description}</h2>
                        </div>
                        { !autoSelectSection &&
                            <div className='close'>
                                <IonIcon style={{ fontSize: 30 }} icon={closeCircle} onClick={() => {
                                    close()
                                }} />
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }

    if (editedFile || selectedFolderId) {
        SECTION_HEADER = 
            (<React.Fragment>
                <div className='header-wrapper'>
                    <div className="header section-header">
                        <div className="back-btn" onClick={() => {
                            if (selectedFolderId) {
                                setSelectedFolderId(null);
                            } else {
                                if (editedFile.id === 0) {
                                    let newFiles = user.files || [];
                                    newFiles.shift();
                                    setUser({ ...user, files: newFiles });
                                }
                                setEditedFileI(null);
                            }
                        }}>
                            <IonIcon icon={arrowBackCircle} />
                        </div>
                        <div
                            className="side"
                            style={ (!autoSelectSection) ? { } : {
                                padding: 0,
                                flexBasis: '100%'
                            } }
                        >
                            <h1 style={{ display: "block" }}>
                                { (editedFile && !editedFile.fileType) ? 
                                "Select Media Type" : 
                                    (editedFile && editedFile.id === 0) ?
                                    (editedFile && ((editedFile.fileType === FileType.TextDocument || editedFile.fileType === FileType.Image)) ?
                                        "Upload new file" : 
                                        "Add Link"
                                    ) : 
                                        (editedFile && editedFile.id > 0) ?
                                        "Edit" : 
                                            selectedFolderId ?
                                            (user.folders.find(folder => folder.id === selectedFolderId).name) : 
                                                "" 
                                }
                            </h1>
                            { (selectedFolderId) &&
                                <IonIcon 
                                    id="edit-folder-btn"
                                    onClick={() => {
                                        setEditedFolderI(user.folders.findIndex(folder => folder.id === selectedFolderId));
                                    }} icon={createOutline} 
                                />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>)
    }

    if (editedFolder) {
        SECTION_HEADER = 
            (<React.Fragment>
                <div className='header-wrapper'>
                    <div className="header section-header">
                        <div className="back-btn" onClick={() => {
                            if (editedFolder.id === 0) {
                                let newFolders = user.folders || [];
                                newFolders.shift();
                                setUser({ ...user, folders: newFolders });
                            }
                            setEditedFolderI(null);
                        }}>
                            <IonIcon icon={arrowBackCircle} />
                        </div>
                        <div
                            className="side"
                            style={ (!autoSelectSection) ? { } : {
                                padding: 0,
                                flexBasis: '100%'
                            } }
                        >
                            <h1 style={{ display: "block" }}>{!editedFolder.id ? "Edit Folder Details" : "Edit Folder Details"}</h1>
                        </div>
                    </div>
                </div>
            </React.Fragment>)
    }

    const profileProgressPercentage: number = Math.floor(taskList.filter((item, i) => i > 1).filter(item => item.completed).length / taskList.filter((item, i) => i > 1).length * 100);
    const profileProgressImgI: number = Math.floor(profileProgressPercentage/10);

    const availabilityConfidenceImgI: number = Math.floor(availabilityConfidencePercentage/10);

    let editedFilesFolderAccess: FileAccess | null = 
        editedFile && 
        editedFile.folderId && 
        user &&
        user.folders && 
        user.folders.find(folder => folder.id === editedFile.folderId) ? 
            user.folders.find(folder => folder.id === editedFile.folderId)!.access : 
            null;
    
    const FileFolderAction = editedFolderI !== null && editedFolder ? "Folder" : "File"

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={ async () => {
                close(nextOpenCycleI);
                setKeyboardOpen(false);
            } }
            disableEnforceFocus
            className="profile-progress-wrapper"
        >
            { (currentSelection === null) &&
                <div className="swipeable-drawer-body" data-visible={open}>
                    <div className='drawer-wrapper'>
                    {/* <div className="drag-indicator"></div> */}
                    <h1>
                        Progress & Help
                        <IonIcon
                            id="close-progress-modal-btn"
                            icon={closeCircle}
                            onClick={() => {
                                close(nextOpenCycleI)
                            }}
                        />
                    </h1>
                    <div className="header-progress">
                        <div
                            className="progress-type"
                            onClick={() => {
                                let el = document.getElementById("todo-1-wrapper");
                                if (el)
                                    el.scrollIntoView({ behavior: "smooth", block: "start" });
                            }}
                        >
                            <img src={PROGRESS[profileProgressImgI]} />
                            <h2>{profileProgressPercentage}%</h2>
                            <p>Profile Progress</p>
                        </div>
                        <div 
                            className="progress-type"
                            onClick={() => {
                                let el = document.getElementById("todo-2-wrapper");
                                if (el)
                                    el.scrollIntoView({ behavior: "smooth", block: "start" });
                            }}
                        >
                            <img 
                                className={(availabilityConfidenceImgI < 4 && taskList && taskList[0] && taskList[1] && taskList[0].completed && taskList[1].completed) ? "pulse no-size" : ""} 
                                src={PROGRESS[availabilityConfidenceImgI]}
                                style={ (availabilityConfidenceImgI < 4 && taskList && taskList[0] && taskList[1] && taskList[0].completed && taskList[1].completed) ? { borderRadius: '100%' } : {} }
                            />
                            <h2>{availabilityConfidencePercentage}%</h2>
                            <p>Availability Confidence</p>
                        </div>
                        <div 
                            className="progress-type"
                            onClick={() => {
                                let el = document.getElementById("todo-3-wrapper");
                                if (el)
                                    el.scrollIntoView({ behavior: "smooth", block: "start" });
                            }}
                        >
                            <div className="img">
                                <IonIcon icon={helpCircleSharp} />
                            </div>
                            <p style={{ marginTop: 18 }}>Other Help Guides or Contact Us</p>
                        </div>
                    </div>
                    <div className="todos">
                        <h2
                            onClick={() => {
                                setTaskListOneCollapsed(!taskListOneCollapsed)
                            }}
                        >
                            Get Started
                            <IonIcon icon={taskListOneCollapsed ? caretDownCircle : caretUpCircle} />
                        </h2>
                        { taskListOneCollapsed &&
                            <div 
                                className="info"
                                onClick={() => {
                                    setTaskListOneCollapsed(!taskListOneCollapsed)
                                }}
                            >
                                { taskListOneAllCompleted ? "All Completed" : "" }
                            </div>
                        }
                        { !taskListOneCollapsed && taskList.map((task, i) => {
                            if (i > 2)
                                return null;
                            return (
                                <div
                                    className="step" 
                                    key={task.id}
                                    data-completed={task.completed ? "true" : "false"} 
                                    id={task.id}
                                    data-task-id={task.id}
                                    data-task-complete={task.completed}
                                    data-task-next-step={task.id === nextStepId && !task.completed}
                                    onClick={() => {
                                        if (task.id === "morecontacts" || task.id === "contacts" || task.id === "agencies") {
                                            // history.push('/contacts/add?promptAdd=true');
                                            history.push("/contacts")
                                            close(nextOpenCycleI)
                                        } else if (task.id === "share") {
                                            close();
                                        } else if (task.id === "verify") {
                                            if (!task.completed) {
                                                window.alert("We will progress your verification and will let you know. No further action is required by you, if you are connected with a verified agency, or two verified hirers, and have added your image & headlines. Push OK to dismiss this alert.");
                                            }
                                        } else {
                                            localStorage.setItem("lastTasksCompleted", completedSteps + "");
                                            setCurrentSelection(task)
                                        }
                                    }}
                                >
                                    <div className="category-badge">
                                        {i+1}
                                    </div>
                                    <div className="side">
                                        { (!orderingFiles) && (!selectedFolderId) &&
                                            <p>{task.completed ? task.descriptionCompleted : task.description}</p>
                                        }
                                        { (orderingFiles) &&
                                            <p>Use the arrows on the files to move them up or down, the ones at the top appear first on your profile.</p>
                                        }
                                        { (selectedFolderId) &&
                                            <p>{user.folders.find(folder => folder.id === selectedFolderId)?.name}</p>
                                        }
                                    </div>
                                    <div className={"action" + ((task.id === nextStepId && !task.completed) ? " pulse" : "")}>
                                        { (task.completed) && <IonIcon icon={checkmarkSharp} style={{ color: "#50D890" }} /> }
                                        { (!task.completed) && <IonIcon icon={arrowForwardOutline} style={{ color: "#333" }} /> }
                                    </div>
                                </div>
                            )
                        }) }
                    </div>
                    <div className="todos" id="todo-2-wrapper">
                        <h2>
                            Availability Confidence
                            <span className="mini-progress">
                                <span>{availabilityConfidencePercentage}%</span>
                                <img className={(availabilityConfidenceImgI < 4 && taskList && taskList[0] && taskList[1] && taskList[0].completed && taskList[1].completed) ? "pulse" : ""} src={PROGRESS[availabilityConfidenceImgI]} />
                            </span>
                        </h2>
                        <button 
                            onClick={() => {
                                AvailabilityAPI
                                    .confirmAuth()
                                    .then(() => {
                                        (window as any).toast("Availability Confirmed", "success")
                                        Utilities.upsertLocalUser({
                                            availabilityLastConfirmed: (new Date()).toISOString()
                                        })
                                        localStorage.setItem("lastSync", Utilities.formatDate(new Date(), "YYYY-MM-DDTHH:MM:SS"))
                                        InternalTracker.trackEvent("Avaialbility Confirmed");
                                        compileList();

                                    }).catch((e) => {
                                        (window as any).toast("Failed to confirm Availability", "error")
                                    })
                            }}
                            id="confirm-availability-btn"
                        >I confirm my availability is accurate</button>
                        {/* <button
                            onClick={() => {
                                close(nextOpenCycleI)
                            }}
                            style={{
                                marginTop: 0
                            }}
                        >
                            Change my availability
                        </button> */}
                        <p 
                            className="info"
                            style={{
                                fontSize: '0.9em',
                                lineHeight: '1.2em'
                            }}
                        >
                            To reset your Availability Confidence score please confirm your current availability is accurate, add or edit future events.
                        </p>
                        <p 
                            className="info"
                            style={{
                                fontSize: '0.9em',
                                lineHeight: '1.2em'
                            }}
                        >
                            <strong
                                onClick={() => {
                                    setHowAvailabilityConfidenceScoreCalcShowing(!howAvailabilityConfidenceScoreCalcShowing);
                                }}
                                style={{
                                    display: 'block',
                                    position: 'relative'
                                }}
                            >
                                How is this scored?
                                <IonIcon 
                                    icon={!howAvailabilityConfidenceScoreCalcShowing ? caretDownCircle : caretUpCircle}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        fontSize: '1.2em'
                                    }}
                                />
                            </strong> 
                            { (howAvailabilityConfidenceScoreCalcShowing) &&
                                "Availability Confidence is scored over 10 days, with each day of inactivity reducing confidence by 10%.  (If you have no events in your calendar your availability confidence score will remain at 0%)."
                            }
                        </p>
                        { howAvailabilityConfidenceScoreCalcShowing &&
                            <div className="tickers">
                                <div>
                                    <h3 id="calendar-last-updated">{ lastUpdatedCalendar ? Utilities.timeSince(lastUpdatedCalendar, true) : "-" }</h3>
                                    <p>Calendar Last Updated</p>
                                </div>
                                <div>
                                    <h3 id="last-confirmed-availability">{ lastConfirmedAvailability ? Utilities.timeSince(lastConfirmedAvailability, true) : "-" }</h3>
                                    <p>Last Confirmed Availability</p>
                                </div>
                                <div>
                                    <h3>{ lastSycnedDate ? Utilities.timeSince(lastSycnedDate, true): "-" }</h3>
                                    <p>Last Shared with contacts</p>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="todos" id="todo-1-wrapper">
                        <h2
                            onClick={() => {
                                setTaskListTwoCollapsed(!taskListTwoCollapsed)
                            }}
                        >
                            Complete Profile
                            <span className="mini-progress">
                                <span>{profileProgressPercentage}%</span>
                                <img src={PROGRESS[profileProgressImgI]} />
                            </span>
                            { (taskListTwoAllCompleted && taskListTwoCollapsed) &&
                                <IonIcon 
                                    icon={taskListTwoCollapsed ? caretDownCircle : caretUpCircle}
                                    style={{
                                        top: 49,
                                        right: 18
                                    }}
                                />
                            }
                        </h2>
                        { taskListTwoCollapsed &&
                            <div 
                                className="info"
                                onClick={() => {
                                    setTaskListTwoCollapsed(!taskListTwoCollapsed)
                                }}    
                            >
                                { taskListTwoAllCompleted ? "All Completed" : "" }
                            </div>
                        }
                        { !taskListTwoCollapsed && taskList.map((task, i) => {
                            if (i < 3)
                                return null;
                            return (
                                <div
                                    className="step" 
                                    key={task.id}
                                    data-completed={task.completed ? "true" : "false"} 
                                    id={task.id}
                                    data-task-id={task.id}
                                    data-task-complete={task.completed}
                                    data-task-next-step={task.id === nextStepId && !task.completed}
                                    onClick={() => {
                                        if (task.id === "morecontacts" || task.id === "contacts" || task.id === "agency") {
                                            history.push('/contacts/add');
                                            close(nextOpenCycleI)
                                        } else if (task.id === "share") {
                                            close();
                                        } else if (task.id === "verify") {
                                            if (!task.completed) {
                                                window.alert("We will progress your verification and will let you know. No further action is required by you, if you are connected with a verified agency, or two verified hirers, and have added your image & headlines. Push OK to dismiss this alert.");
                                            }
                                        } else {
                                            localStorage.setItem("lastTasksCompleted", completedSteps + "");
                                            setCurrentSelection(task)
                                        }
                                    }}
                                >
                                    <div className="category-badge">
                                        {i+1}
                                    </div>
                                    <div className="side">
                                        <p>{task.completed ? task.descriptionCompleted : task.description}</p>
                                    </div>
                                    <div className={"action" + ((task.id === nextStepId && !task.completed) ? " pulse" : "")}>
                                        { (task.completed) && <IonIcon icon={checkmarkSharp} style={{ color: "#50D890" }} /> }
                                        { (!task.completed) && <IonIcon icon={arrowForwardOutline} style={{ color: "#333" }} /> }
                                    </div>
                                </div>
                            )
                        }) }
                    </div>
                    <div className="todos help" id="todo-3-wrapper">
                        <h2>Explore Help Guides</h2>
                        {[
                            { label: "Recent / Favourite events", slug: "recents" },
                            { label: "I'm available now event type", slug: "availability" },
                            { label: "Delete events", slug: "delete" },
                            { label: "Editing events", slug: "edit" },
                            // { label: "Time presets", slug: "presets" },
                            { label: "Multi-day events", slug: "multiday" }
                        ].map((item, i) => {
                            return (
                                <div
                                    className="step" 
                                    id={item.slug}
                                    onClick={ async () => {

                                        close();
                                        setCurrentStep(0);

                                        let clearEventsSelectionBtn = document.getElementById("clear-selection-btn");
                                        if (clearEventsSelectionBtn) {
                                            clearEventsSelectionBtn.click();
                                            await new Promise<void>((resolve) => { setTimeout(() => { resolve() }, 700) })
                                        }

                                        if (item.slug === "delete") {
                                            let undeletedEvents = await hasUndeletedEvents();
                                            if (!undeletedEvents) {
                                                (window as any).toast("You don't have any events yet, please create one first")
                                                return;
                                            }
                                            setSteps([
                                                { selector: '.event-item', content: 'Tap on the event you want to delete' },
                                                { selector: '#delete-event-btn', content: 'Tap on the delete button, then confirm deletion' }
                                            ])
                                            if (!window.location.pathname.startsWith("/calendar")) {
                                                history.push("/calendar");
                                            }
                                        } else if (item.slug === "recents") {
                                            let recents: Event[] = await getRecentUniqueEvents();
                                            if (recents.length === 0) {
                                                (window as any).toast("You don't have any custom events yet, please create one first")
                                                return;
                                            }
                                            Utilities.showTourPaging(true);
                                            setSteps([
                                                { selector: '.react-calendar__viewContainer', content: 'Select the days you want to add an event to. And click the next arrow' },
                                                { selector: '.favorites-btn', content: 'Tap on the favourites button' },
                                                { selector: 'ion-segment-button[value="recents"]', content: 'Switch to the recents tab from favorites' },
                                                { selector: 'section[data-section="recents"] .event-item', content: 'Tap on the event you want to reuse' },
                                                { selector: '#save-event-btn', content: 'Tap on the save button' }
                                            ])
                                            if (!window.location.pathname.startsWith("/calendar")) {
                                                history.push("/calendar");
                                            }
                                        } else if (item.slug === "edit") {
                                            let undeletedEvents = await hasUndeletedEvents();
                                            if (!undeletedEvents) {
                                                (window as any).toast("You don't have any events yet, please create one first")
                                                return;
                                            }
                                            setSteps([
                                                { selector: '.event-item', content: 'Tap on the event you want to edit' },
                                                { selector: '#save-event-btn', content: 'Tap on the save button, once you made your edits' }
                                            ])
                                            if (!window.location.pathname.startsWith("/calendar")) {
                                                history.push("/calendar");
                                            }
                                        } else if (item.slug === "multiday") {
                                            // history.push("/help/" + item.slug);
                                            // TODO allow more select, make sure have all recents
                                            Utilities.showTourPaging(true);
                                            setSteps([
                                                { selector: '.react-calendar__viewContainer', content: 'Select the days you want to add an event to. And click the next arrow' },
                                                { selector: '.add-event-btn', content: 'Tap on the add event button' },
                                                { selector: '#save-event-btn', content: 'Tap on the save button, once you made your edits' }
                                            ])
                                            if (!window.location.pathname.startsWith("/calendar")) {
                                                history.push("/calendar");
                                            }
                                        } else if (item.slug === "availability") {
                                            history.push("/help/" + item.slug);
                                        }
                                        
                                        if (item.slug !== "availability") {
                                            setTimeout(() => {
                                                setIsOpen(true);
                                            }, 500)
                                        }

                                    }}
                                >
                                    <div className="category-badge">
                                        {i+1}
                                    </div>
                                    <div className="side">
                                        <p>{item.label}</p>
                                    </div>
                                    <div className="action">
                                        <IonIcon icon={arrowForwardOutline} style={{ color: "#333" }} />
                                    </div>
                                </div>
                            )
                        }) }
                    </div>
                    <div className="todos contact" onClick={() => {
                        InternalTracker.trackEvent("Contacted Us", {
                            source: "Profile Drawer"
                        })
                        window.open("mailto:support@updatedge.com?subject=Version 2 Support Request") 
                    }}>
                        <h2>Still haven't got your answer?</h2>
                        <button>Contact Us by E-mail</button>
                    </div>
                    { (explicitOpen) ?
                        <div className="footer">
                            <button 
                                onClick={() => {
                                    close(nextOpenCycleI)
                                }}
                                className='main'
                                style={{
                                    width: '100%',
                                    marginLeft: 0,
                                    flexBasis: '100%'
                                }}
                            >
                                Close
                            </button>
                        </div>
                        :
                        <div className="footer">
                            <button onClick={() => {
                                close(nextOpenCycleI, true);
                            }}>Never show again</button>
                            <button className="main" onClick={() => {
                                close(nextOpenCycleI)
                            }}>Remind me later</button>
                        </div>
                    }
                </div>
                </div>
            }

            { (currentSelection && currentSelection.id === "sectorssubsectors") &&
                <div className="swipeable-drawer-body" data-visible={open}>
                    <div className='drawer-wrapper'>
                        {SECTION_HEADER}
                        <div className="open-task">
                            {/* <SectorSkillManager
                                userSectorsProps={user.industries}
                                onUpdate={(sectors) => {
                                    setUser({ ...user, industries: sectors });
                                }}
                            /> */}
                            <SkillManagerEnhanced 
                                BOTTOM_OPTIONS_STYLE={{}}
                                onAttributesChange={(attributes) => {
                                    setUser({...user, workerAttributes: attributes});
                                }}
                                keyboardOpen={keyboardOpen}
                            />
                            <button className="main save-progress-btn" onClick={() => {
                                setLoadingMsg("Updating your skills");

                                UserAPI.update({
                                    workerAttributes: user.workerAttributes
                                } as UserDTO).then(res => {
                                    reloadLocalData(autoSelectSection);
                                    InternalTracker.trackEvent("Sectors & Skills Updated");
                                }).catch(e => {
                                    console.error(e);
                                    setLoadingMsg(null);
                                    (window as any).toast("Failed to update settings", "error")
                                })

                            }}>Save</button>
                        </div>
                    </div>
                </div>
            }

            { (currentSelection && currentSelection.id === "headline") &&
                <div className="swipeable-drawer-body" data-visible={open}>
                    <div className='drawer-wrapper'>
                        {SECTION_HEADER}
                        <div className="open-task">
                            { (user) &&
                                <IonItem lines="none" style={{
                                    marginBottom: 16
                                }}>
                                    {/* <IonLabel position="stacked">Headline</IonLabel> */}
                                    {(user.headline && typing && user.headline.length > 220) &&
                                        <div
                                            className='left'
                                            style={{
                                                position: "absolute",
                                                top: 5,
                                                right: 0,
                                                color: "red"
                                            }}
                                        >
                                            {250 - user.headline.length} characters left
                                        </div>
                                    }
                                    <IonTextarea
                                        rows={2}
                                        placeholder="Describe Yourself for Contacts" 
                                        value={user.headline || ""} 
                                        onIonInput={(e) => { 
                                            setUser({...user, headline: Utilities.capitalizeFirstLetter((e.target as HTMLIonTextareaElement).value).substr(0, 250) }) }
                                        }
                                        onFocus={() => {
                                            setTyping(true);
                                        }}
                                        onBlur={() => {
                                            setTyping(false);
                                        }}
                                    />
                                </IonItem>
                            }
                            <button className="main save-progress-btn" onClick={() => {

                                setLoadingMsg("Saving Settings...");

                                UserAPI.update({
                                    headline: user.headline
                                } as UserDTO).then(res => {
                                    reloadLocalData(autoSelectSection);

                                    InternalTracker.trackEvent("Headline Updated");
                                }).catch(e => {
                                    console.error(e);
                                    setLoadingMsg(null);
                                    (window as any).toast("Failed to update settings", "error")
                                })

                            }}>Save</button>
                        </div>
                    </div>
                </div>
            }

            { (currentSelection && currentSelection.id === "files") &&
                <div className="swipeable-drawer-body" data-visible={open}>
                    <div className='drawer-wrapper'>
                        {SECTION_HEADER}
                        <div className="open-task">
                            <div className='files'>
                                { (editedFile && !editedFile.fileType) &&
                                    <div className='file-type-selector'>
                                        { [
                                            {
                                                name: FileType.TextDocument,
                                                label: "Upload documents & images",
                                                description: "Your CV, your photos, qualifications, lesson plans, recommendation letters",
                                                logo: TypeDocument,
                                                id: "document"
                                            },
                                            {
                                                name: FileType.Link,
                                                label: "Add links to websites",
                                                description: "To highlight websites that show your work and goals",
                                                logo: TypeLink,
                                                id: "link"
                                            },
                                            {
                                                name: FileType.VideoLink,
                                                label: "Add video links",
                                                description: "Tell your story, goals, achievements",
                                                logo: TypeVideoLink,
                                                id: "youtube"
                                            }
                                        ].map(type => {
                                            return (
                                                <div 
                                                    data-type={type.id}
                                                    className='type'
                                                    onClick={() => {
                                                        let newFiles = user.files || [];
                                                        let item = newFiles[editedFileI];
                                                        item.fileType = type.name;
                                                        newFiles[editedFileI] = item;
                                                        setUser({ ...user, files: newFiles });
                                                        InternalTracker.trackEvent("File Type Selected", {
                                                            type: type.name,
                                                        });
                                                    }}
                                                >
                                                    <div className='img'>
                                                        <img src={type.logo} />
                                                    </div>
                                                    <div className='text'>
                                                        <div>
                                                            <p>{type.label}</p>
                                                            <p>{type.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) }
                                    </div>
                                }
                                { (user && user.files && user.files.length === 0 && user.folders && user.folders.length === 0 && !editedFile && !editedFolder) &&
                                    <div className='no-items'>
                                        <div>
                                            <IonIcon icon={informationCircleOutline} />
                                            <p>No files added yet</p>
                                        </div>
                                    </div>
                                }
                                { (user && user.folders) && user.folders.map((folder, folderI) => {
                                    if (editedFolderI !== null && editedFolder && folderI === editedFolderI && editedFileI === null) {
                                        return (
                                            <div className='folder-edited'>
                                                <InlineSelect
                                                    id={"file-access-selector"}
                                                    selected={user.folders[folderI].access.toString()}
                                                    options={[
                                                        { id: "0" /*FileAccess.Public.toString()*/, icon: <IonIcon icon={globeOutline} />, label: "Public" },
                                                        { id: "1" /* FileAccess.PublicToContacts.toString() */, icon: <IonIcon icon={peopleOutline} />, label: "Contacts Only" },
                                                        { id: "2" /* FileAccess.Private.toString() */, icon: <IonIcon icon={documentLockOutline} />, label: "On Request" },
                                                        { id: "3" /* FileAccess.Private.toString() */, icon: <IonIcon icon={documentLockOutline} />, label: "Private" },
                                                    ]}
                                                    onSelect={(newAccess) => {
                                                        InternalTracker.trackEvent(FileFolderAction + " Access Type Changed", {
                                                            type: newAccess,
                                                        })
                                                        localStorage.setItem("lastFileAccessType", newAccess);
                                                        let newFolders = user.folders || [];
                                                        let item = newFolders[folderI];
                                                        item.access = newAccess === "0" ? FileAccess.Public : newAccess === "1" ? FileAccess.PublicToContacts : newAccess === "2" ? FileAccess.OnRequest : FileAccess.Private
                                                        newFolders[folderI] = item;
                                                        setUser({ ...user, files: newFolders });
                                                    }}
                                                />
                                                <div className='file-access-warn'>
                                                    <IonIcon icon={warning} />
                                                    <p>
                                                        { 
                                                            editedFolder.access === FileAccess.Public ?
                                                            "Content saved is accessible to all Updatedge users" :
                                                                editedFolder.access === FileAccess.PublicToContacts ?
                                                                "Content saved is accessible by your contacts, and organization colleagues's contacts. File names are visible to others" :
                                                                    editedFolder.access === FileAccess.OnRequest ?
                                                                    "Content saved is accessible by your selected contacts only. File names are visible to others too" :
                                                                    "Content saved is accessible by your selected contacts only."
                                                        }
                                                    </p>
                                                </div>
                                                { editedFolder && (editedFolder.access === 2 || editedFolder.access === 3) &&
                                                    <div className="line-down-container clearfix">
                                                        <div className="line"></div>
                                                        <button onClick={ async () => {
                                                            if (editedFolder.id === 0) {
                                                                (window as any).toast("Before sharing, please save")
                                                            } else {
                                                                let res = await UserAPI.updateFolderMetadata(editedFolder).catch(e => {
                                                                    (window as any).toast(e?.response?.data || "Failed to update folder", "error");
                                                                })
                                                                setLoadingMsg(null);
                                                                reloadLocalData(autoSelectSection, false);
                                                                setEditedFolderI(null);
                                                                window.location.href = "/sharefolder/" + editedFolder.id;   
                                                            }
                                                        }}>Share with specific contacts</button>
                                                    </div>
                                                }
                                                <IonInput
                                                    placeholder="Folder Name"
                                                    value={folder.name} 
                                                    onIonInput={(e) => {
                                                        let newFolders = user.folders || [];
                                                        let item = newFolders[folderI];
                                                        item.name = (e.target as HTMLIonInputElement).value.toString()
                                                        newFolders[folderI] = item;
                                                        setUser({ ...user, folders: newFolders });
                                                    }}
                                                    onIonBlur={() => {
                                                        InternalTracker.trackEvent(FileFolderAction + " Name Changed", {
                                                            name: editedFolder ? editedFolder.name : editedFile ? editedFile.fileName : "",
                                                        })
                                                    }}
                                                    tabIndex={1}
                                                />
                                                <IonTextarea
                                                    rows={2}
                                                    placeholder="Description" 
                                                    value={editedFolder ? (editedFolder.description || "") : ""}
                                                    onIonInput={(e) => { 
                                                        let newFolders = user.folders || [];
                                                        let item = newFolders[editedFolderI];
                                                        item.description = (e.target as HTMLIonTextareaElement).value ? Utilities.capitalizeFirstLetter((e.target as HTMLIonTextareaElement).value).substr(0, 250) : null;
                                                        newFolders[editedFolderI] = item;
                                                        setUser({ ...user, folders: newFolders });
                                                    }}
                                                    onFocus={() => {
                                                        setTyping(true);
                                                    }}
                                                    onBlur={() => {
                                                        setTyping(false);
                                                        InternalTracker.trackEvent(FileFolderAction + " Description Changed", {
                                                            name: editedFolder ? editedFolder.description : editedFile ? editedFile.description : "",
                                                        })
                                                    }}
                                                    tabIndex={2}
                                                />
                                            </div> 
                                        )
                                    } else if (editedFolderI === null && editedFileI === null && selectedFolderId === null) {
                                        return (
                                            <div 
                                                className='file folder item'
                                                onClick={() => {
                                                    setSelectedFolderId(folder.id);
                                                }}
                                                data-name={folder.name}
                                                data-access={folder.access}
                                            >
                                                <div
                                                    className='preview'
                                                >
                                                    <div>
                                                        <IonIcon icon={folderSharp} />
                                                    </div>
                                                </div>
                                                <div className='meta'>
                                                    <h2>
                                                        {folder.name}
                                                    </h2>
                                                    <h3>
                                                        <label style={{ marginRight: 6 }}>
                                                            <IonIcon icon={documentSharp} />
                                                            {user.files.filter(f => f.folderId === folder.id).length} Files
                                                        </label>
                                                        <label>
                                                            <IonIcon icon={folder.access === 0 ? globeOutline : folder.access === 1 ? peopleOutline : folder.access === 2 ? helpCircleOutline : documentLockOutline} />
                                                            {folder.access === 0 ? "Public" : folder.access === 1 ? "Only Contacts" : folder.access === 2 ? "On Request" : "Private"}
                                                        </label>
                                                    </h3>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return null;
                                    }
                                }) }
                                { (user && user.files) && user.files.map((file, fileI) => {
                                    if (editedFileI !== null && editedFile.fileType && fileI === editedFileI) {
                                        return (
                                            <div className='file-edited'>
                                                <InlineSelect
                                                    hideUnselectedOptions={editedFilesFolderAccess !== null}
                                                    id={"file-access-selector"}
                                                    selected={editedFilesFolderAccess !== null ? editedFilesFolderAccess.toString() : user.files[fileI].access.toString()}
                                                    options={[
                                                        { id: "0" /*FileAccess.Public.toString()*/, icon: <IonIcon icon={globeOutline} />, label: "Public" },
                                                        { id: "1" /* FileAccess.PublicToContacts.toString() */, icon: <IonIcon icon={peopleOutline} />, label: "Contacts Only" },
                                                        { id: "2" /* FileAccess.Private.toString() */, icon: <IonIcon icon={helpCircleOutline} />, label: "On Request" },
                                                        { id: "3" /* FileAccess.Private.toString() */, icon: <IonIcon icon={documentLockOutline} />, label: "Private" },
                                                    ]}
                                                    onSelect={(newAccess) => {
                                                        InternalTracker.trackEvent(FileFolderAction + " Access Type Changed", {
                                                            type: newAccess,
                                                        })
                                                        localStorage.setItem("lastFileAccessType", newAccess);
                                                        let newFiles = user.files || [];
                                                        let item = newFiles[fileI];
                                                        item.access = newAccess === "0" ? FileAccess.Public : newAccess === "1" ? FileAccess.PublicToContacts : newAccess === "2" ? FileAccess.OnRequest : FileAccess.Private
                                                        newFiles[fileI] = item;
                                                        setUser({ ...user, files: newFiles });
                                                    }}
                                                />
                                                <div className='file-access-warn'>
                                                    <IonIcon icon={warning} />
                                                    <p>
                                                        { (editedFilesFolderAccess !== null) &&
                                                            (editedFilesFolderAccess === FileAccess.Public ?
                                                            "This file is accessible by all Updatedge users (due to the folder's access type)" :
                                                                editedFilesFolderAccess === FileAccess.PublicToContacts ?
                                                                "This file is only accessible by yours contacts (due to the folder's access type), but the name of the file is visible to everyone." :
                                                                    editedFilesFolderAccess === FileAccess.OnRequest ?
                                                                    "This file is only accessible by your selected contacts (due to the folder's access type), but the name of the file is visible to everyone" :
                                                                    "This file is only accessible by your selected contacts (due to the folder's access type)"
                                                        )}
                                                        { (editedFilesFolderAccess === null) &&
                                                            (editedFile.access === FileAccess.Public ?
                                                            "This file is accessible by all Updatedge users." :
                                                                editedFile.access === FileAccess.PublicToContacts ?
                                                                "This file is only accessible by yours contacts, but the name of the file is visible to everyone." :
                                                                    editedFile.access === FileAccess.OnRequest ?
                                                                    "This file is only accessible by your selected contacts below, but the name of the file is visible to everyone." :
                                                                    "This file is only accessible by your selected contacts below."
                                                        )}
                                                    </p>
                                                </div>
                                                {/* <div>
                                                    {editedFolder?.access}_{editedFile?.access}_{editedFilesFolderAccess}
                                                </div> */}
                                                { 
                                                    (editedFolder ? 
                                                        (editedFolder.access === 2 || editedFolder.access === 3) : 
                                                            editedFilesFolderAccess !== null ? 
                                                            (editedFilesFolderAccess === 2 || editedFilesFolderAccess === 3) : 
                                                                editedFile !== null ?
                                                                (editedFile.access === 2 || editedFile.access === 3) : false
                                                    ) &&
                                                    <div className="line-down-container clearfix">
                                                        <div className="line"></div>
                                                        <button onClick={ async () => {
                                                            if (editedFile.id === 0) {
                                                                (window as any).toast("Before sharing, please save")
                                                            } else {
                                                                let res = await UserAPI.updateFileMetadata(editedFile).catch(e => {
                                                                    (window as any).toast(e?.response?.data || "Failed to update file", "error");
                                                                })
                                                                setLoadingMsg(null);
                                                                reloadLocalData(autoSelectSection, false);
                                                                setEditedFileI(null);
                                                                window.location.href = "/sharefile/" + editedFile.id;   
                                                            }
                                                        }}>Share with specific contacts</button>
                                                    </div>
                                                }
                                                { editedFile && (editedFile.fileType === FileType.TextDocument || editedFile.fileType === FileType.Image) && editedFile.id === 0 &&
                                                    <button onClick={() => openFileSelector()}>
                                                        { (plainFiles && plainFiles[0]) ? 
                                                            ("Replace File (" + plainFiles[0].name + ")") :
                                                            "Select File"
                                                        }
                                                    </button>
                                                }
                                                { (editedFile.fileType === FileType.VideoLink || editedFile.fileType === FileType.Link) &&
                                                    <div className="input-with-icon">
                                                        <IonIcon icon={earthOutline} />
                                                        <IonInput
                                                            placeholder={editedFile.fileType === FileType.VideoLink ? "YouTube Link" : "Link"}
                                                            value={file.link} 
                                                            readonly={editedFile.id !== 0}
                                                            onIonInput={(e) => {
                                                                let newFiles = user.files || [];
                                                                let item = newFiles[fileI];
                                                                item.link = (e.target as HTMLIonInputElement).value.toString();
                                                                newFiles[fileI] = item;
                                                                setUser({ ...user, files: newFiles });
                                                            }}
                                                            tabIndex={3}
                                                            onBlur={ async () => {
                                                                let previewLinkUrl = "";
                                                                
                                                                if (user.files[fileI].link && !user.files[fileI].link.startsWith("http")) {
                                                                    let newFiles = user.files || [];
                                                                    let item = newFiles[fileI];
                                                                    item.link = "https://" + item.link
                                                                    previewLinkUrl = item.link
                                                                    newFiles[fileI] = item;
                                                                    setUser({ ...user, files: newFiles });
                                                                } else if (user.files[fileI].link) {
                                                                    previewLinkUrl = user.files[fileI].link;
                                                                }

                                                                if (previewLinkUrl) {
                                                                    const base64Preview = await UserAPI.fetchLinkPreview(previewLinkUrl);
                                                                    if (base64Preview) {
                                                                        var resized = await Utilities.resizeAndRotateImage(base64Preview as string)
                                                                        if (resized) {
                                                                            let newFiles = user.files || [];
                                                                            let item = newFiles[fileI];
                                                                            item.thumbnailImg = resized
                                                                            newFiles[fileI] = item;
                                                                            setUser({ ...user, files: newFiles });
                                                                        }
                                                                    }
                                                                }

                                                                InternalTracker.trackEvent("File Link Changed", {
                                                                    text: previewLinkUrl
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                <div className='input-with-icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V285.7l-86.8 86.8c-10.3 10.3-17.5 23.1-21 37.2l-18.7 74.9c-2.3 9.2-1.8 18.8 1.3 27.5H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM549.8 235.7l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6l-29.4 29.4-71-71 29.4-29.4c15.6-15.6 40.9-15.6 56.6 0zM311.9 417L441.1 287.8l71 71L382.9 487.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9z"/></svg>
                                                    <IonInput
                                                        placeholder="File Name"
                                                        value={file.fileName} 
                                                        onIonInput={(e) => {
                                                            let newFiles = user.files || [];
                                                            let item = newFiles[fileI];
                                                            item.fileName = (e.target as HTMLIonInputElement).value.toString();
                                                            newFiles[fileI] = item;
                                                            setUser({ ...user, files: newFiles });
                                                        }}
                                                        tabIndex={4}
                                                        onIonBlur={() => {
                                                            InternalTracker.trackEvent(FileFolderAction + " Name Changed", {
                                                                name: editedFolder ? editedFolder.name : editedFile ? editedFile.fileName : "",
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                { (editedFile && (editedFile.fileType === FileType.TextDocument || editedFile.fileType === FileType.Image || editedFile.fileType === FileType.Link)) &&
                                                    <div className='input-with-icon'>
                                                        <IonIcon icon={pricetagOutline} />
                                                        <IonInput
                                                            placeholder="Create or Select Category"
                                                            value={Utilities.capitalizeFirstLetter(file.fileCategoryName)} 
                                                            onIonInput={(e) => {
                                                                let newFiles = user.files || [];
                                                                let item = newFiles[fileI];
                                                                item.fileCategoryName = (e.target as HTMLIonInputElement).value.toString() ? (e.target as HTMLIonInputElement).value.toString().toLowerCase() : "";
                                                                setFileCategorySuggestionsQuery(item.fileCategoryName);
                                                                newFiles[fileI] = item;
                                                                setUser({ ...user, files: newFiles });
                                                            }}
                                                            onIonBlur={() => {
                                                                setFileCategorySuggestionsQuery("");
                                                                InternalTracker.trackEvent("File Category Changed", {
                                                                    name: editedFile.fileCategoryName
                                                                })
                                                            }}
                                                            tabIndex={5}
                                                        />
                                                    </div>
                                                }
                                                { (editedFile && (editedFile.fileType === FileType.TextDocument || editedFile.fileType === FileType.Image || editedFile.fileType === FileType.Link)) &&
                                                    <div
                                                        className="section-content-2"
                                                        style={{
                                                            padding: "0 0 0 32px",
                                                            marginBottom: -4
                                                        }}
                                                    >
                                                        <div className="suggestions">
                                                            { /*(user && user.config && user.config.fileCategorySuggestions) && user.config.fileCategorySuggestions*/
                                                            [
                                                                { "name": "CV" },
                                                                { "name": "Short Profile" },
                                                                { "name": "Skills List" },
                                                                { "name": "My Profile Picture" },
                                                                { "name": "Qualifications" },
                                                                { "name": "QTS Certificate " },
                                                                { "name": "Teaching Qualification " },
                                                                { "name": "Induction Certificate " },
                                                                { "name": "Degree Certificate" },
                                                                { "name": "Safeguarding Certificate" },
                                                                { "name": "PREVENT Certificate" },
                                                                { "name": "Work Example " },
                                                            ].concat(userCategories.map(uc => { return { name: uc } })).map(suggestionItem => {
                                                                if (fileCategorySuggestionsQuery && suggestionItem.name.toLowerCase().indexOf(fileCategorySuggestionsQuery.toLowerCase()) === -1) return null;
                                                                return (
                                                                    <div key={suggestionItem.name} onClick={() => {
                                                                        let newFiles = user.files || [];
                                                                        let item = newFiles[editedFileI];
                                                                        item.fileCategoryName = suggestionItem.name;
                                                                        newFiles[editedFileI] = item;
                                                                        setUser({ ...user, files: newFiles });
                                                                        setFileCategorySuggestionsQuery("");
                                                                        InternalTracker.trackEvent("File Category Suggestion Picked", {
                                                                            name: suggestionItem.name
                                                                        })
                                                                    }}>
                                                                        {Utilities.capitalizeFirstLetter(suggestionItem.name)}
                                                                    </div>
                                                                )
                                                            }) }
                                                        </div>
                                                    </div>
                                                }
                                                <div className='input-with-icon'>
                                                    <IonIcon icon={ellipsisHorizontalOutline} />
                                                    <IonTextarea
                                                        rows={2}
                                                        placeholder="Description" 
                                                        value={editedFile ? (editedFile.description || "") : ""}
                                                        onIonChange={(e) => { 
                                                            let newFiles = user.files || [];
                                                            let item = newFiles[editedFileI];
                                                            item.description = (e.target as HTMLIonTextareaElement).value ? Utilities.capitalizeFirstLetter((e.target as HTMLIonTextareaElement).value).substr(0, 250) : null;
                                                            newFiles[editedFileI] = item;
                                                            setUser({ ...user, files: newFiles });
                                                        }}
                                                        onFocus={() => {
                                                            setTyping(true);
                                                        }}
                                                        onBlur={() => {
                                                            setTyping(false);
                                                            InternalTracker.trackEvent(FileFolderAction + " Description Changed", {
                                                                name: editedFolder ? editedFolder.name : editedFile ? editedFile.fileName : "",
                                                            })
                                                        }}
                                                        tabIndex={6}
                                                    />
                                                </div>
                                                { (editedFile && user.folders && user.folders.length !== 0) &&
                                                    <div className='input-with-icon'>
                                                        <IonIcon icon={folderOutline} />
                                                        <select
                                                            placeholder="Select Folder"
                                                            value={editedFile.folderId?.toString() || "0"} 
                                                            onChange={({ target: { name, value } }) => {
                                                                let newFiles = user.files || [];
                                                                let item = newFiles[fileI];
                                                                item.folderId = parseInt(value);
                                                                newFiles[fileI] = item;
                                                                setUser({ ...user, files: newFiles });
                                                                InternalTracker.trackEvent("File Folder Changed", {
                                                                    fileId: editedFile.id,
                                                                    folderId: parseInt(value)
                                                                })
                                                            }}
                                                        >
                                                            <option value={0}>Select Folder (Optional)</option>
                                                            { user.folders.map(folder => {
                                                                return (
                                                                    <option key={folder.id} value={folder.id}>
                                                                        {folder.name}
                                                                    </option>
                                                                )
                                                            }) }
                                                        </select>
                                                    </div>
                                                }
                                            </div> 
                                        )
                                    } else if (editedFileI === null && editedFolderI === null) {
                                        if (
                                            (selectedFolderId === null && file.folderId === 0) || 
                                            (selectedFolderId !== null && file.folderId === selectedFolderId)
                                        ) {
                                            return (
                                                <FileListItem
                                                    file={file}
                                                    folder={file.folderId ? user.folders.find(f => f.id === file.folderId) : null}
                                                    fileI={fileI}
                                                    onClick={() => {
                                                        setFileCategorySuggestionsQuery("");
                                                        setEditedFileI(fileI);
                                                    }}
                                                    onOrder={(fileI, asc) => {
                                                        orderFile(fileI, asc)
                                                    }}
                                                    userFiles={user.files}
                                                    showAccess={true}
                                                    showDescription={true}
                                                    orderingFiles={orderingFiles}
                                                />
                                            )
                                        } else {
                                            return null;
                                        }
                                    } else {
                                        return null;
                                    }
                                }) }
                            </div>
                            { (editedFileI !== null && editedFile.fileType && user.files[editedFileI].id === 0) &&
                                <div className="options">
                                    <button
                                        onClick={() => {
                                            let newFiles = user.files || [];
                                            newFiles.shift();
                                            setUser({ ...user, files: newFiles });
                                            setEditedFileI(null);
                                            InternalTracker.trackEvent("File Discarded");
                                        }}
                                        className="main delete"
                                    >
                                        Discard
                                    </button>
                                    <button 
                                        className="main save-progress-btn" 
                                        id="start-file-upload-btn"
                                        onClick={ async () => {
                                            const userFileRef = user.files[editedFileI]
                                            console.log(userFileRef);

                                            var allowedUploadFormats = (user && user.config && user.config.allowedFileTypesToUpload) ? user.config.allowedFileTypesToUpload : [];
                                            
                                            if (!userFileRef.fileName) {
                                                (window as any).toast("Enter a name for this file", "error"); return;
                                            } else if (editedFile.fileType === FileType.TextDocument || editedFile.fileType === FileType.Image) {
                                                if (!plainFiles[0]) {
                                                    (window as any).toast("Select a file to upload", "error"); return;
                                                } else if (!editedFile.fileFormat) {
                                                    (window as any).toast("Invalid File", "error"); return;
                                                } else if (allowedUploadFormats.indexOf(editedFile.fileFormat) === -1) {
                                                    (window as any).toast("Uploading " + editedFile.fileFormat + " is not allowed", "error"); return;
                                                } else if (!userFileRef.fileCategoryName) {
                                                    (window as any).toast("Enter a category for this file", "error"); return;
                                                }
                                            } else if (editedFile.fileType === FileType.VideoLink || editedFile.fileType === FileType.Link) {
                                                if (!Utilities.isValidHttpUrl(editedFile.link)) {
                                                    (window as any).toast("Invalid Link", "error"); return;
                                                } else if (editedFile.fileType === FileType.Link && !userFileRef.fileCategoryName) {
                                                    (window as any).toast("Enter a category for this link", "error"); return;
                                                } else if (editedFile.fileType === FileType.VideoLink && !isValidUrl(editedFile.link)) {
                                                    (window as any).toast("Invalid YouTube link", "error"); return;
                                                }
                                            }

                                            InternalTracker.trackEvent("File Saved");

                                            try {
                                                setLoadingMsg(editedFile.fileType === FileType.VideoLink || editedFile.fileType === FileType.Link ? "Saving Link..." : "Uploading File...");
                                                const fileRef: UserFile = (await UserAPI.generateUploadLink(userFileRef)) as any;

                                                if (fileRef && fileRef.accessLink) {

                                                    let data, uploadRes;

                                                    if (plainFiles[0]) {
                                                        data = new File([plainFiles[0]], fileRef.id + "." + userFileRef.fileFormat);
                                                
                                                        uploadRes = await axios({
                                                            method: 'put',
                                                            url: fileRef.accessLink,
                                                            headers: { 
                                                            'x-ms-blob-type': 'BlockBlob', 
                                                            'Content-Type': mime.lookup(data.name)
                                                            },
                                                            data : data
                                                        });
                                                    }

                                                    let uploadResPreview: any = null;
                                                    
                                                    if (userFileRef.thumbnailImg && fileRef.previewAccessLink) {
                                                        uploadResPreview = await axios({
                                                            method: 'put',
                                                            url: fileRef.previewAccessLink,
                                                            headers: { 
                                                            'x-ms-blob-type': 'BlockBlob', 
                                                            'Content-Type': data ? mime.lookup(data.name) : 'image/png'
                                                            },
                                                            data: userFileRef.thumbnailImg
                                                        })
                                                    }
                                                    
                                                    if (uploadRes || uploadResPreview || (editedFile.fileType === FileType.VideoLink || editedFile.fileType === FileType.Link)) {
                                                        await UserAPI.markFileUplaoded(fileRef.id, uploadResPreview !== null && uploadResPreview !== undefined);
                                                        reloadLocalData(autoSelectSection, true)
                                                        setEditedFileI(null);
                                                        (window as any).toast(editedFile.fileType === FileType.VideoLink || editedFile.fileType === FileType.Link ? "Link Saved" : "File Uploaded", "success");
                                                    }
                                                } else {
                                                    reloadLocalData(autoSelectSection, true)
                                                    setEditedFileI(null);
                                                }
                                            } catch (e) {
                                                setLoadingMsg(null);
                                                console.log(e && e.response && e.response.data ? e.response.data : e);
                                                if (e && e.response && e.response.data) {
                                                    (window as any).toast(e.response.data, "error")
                                                }
                                            }
                                        }}
                                    >
                                        { editedFile.fileType === FileType.VideoLink || editedFile.fileType === FileType.Link ? "Add" : "Save" }
                                    </button>
                                </div>
                            }
                            { (editedFolderI !== null) &&
                                <div className="options">
                                    { user.folders[editedFolderI].id !== 0 ?
                                        <button
                                            onClick={ async () => {
                                                setLoadingMsg("Deleting Folder...")
                                                InternalTracker.trackEvent("Folder Deleted");
                                                UserAPI.deleteFolder(editedFolder.id).then(data => {
                                                    (window as any).toast("Folder Deleted", "success");
                                                    setEditedFolderI(null);
                                                    setSelectedFolderId(null);
                                                    reloadLocalData(autoSelectSection, true);
                                                }).catch(e => {
                                                    (window as any).toast(e?.response?.data || "Failed to delete folder", "error");
                                                    setEditedFolderI(null);
                                                    setSelectedFolderId(null);
                                                    reloadLocalData(autoSelectSection, true);
                                                })
                                            }}
                                            className="main delete"
                                        >
                                            <IonIcon icon={trashOutline} />
                                            Delete
                                        </button>
                                        :
                                        <button
                                            onClick={ async () => {
                                                let newFolders = user.folders || [];
                                                newFolders.shift();
                                                setUser({ ...user, folders: newFolders });
                                                setEditedFolderI(null);
                                                InternalTracker.trackEvent("Folder Discarded");
                                            }}
                                            className="main delete"
                                        >
                                            Discard
                                        </button>
                                    }
                                    <button 
                                        id="start-file-upload-btn"
                                        className="main save-progress-btn" 
                                        onClick={ async () => {
                                            InternalTracker.trackEvent("Folder Saved");
                                            setLoadingMsg(editedFolder.id === 0 ? "Saving Folder..." : "Updating Folder...")
                                            let res = await UserAPI.updateFolderMetadata(editedFolder).catch(e => {
                                                (window as any).toast(e?.response?.data || "Failed to update folder", "error");
                                            })
                                            setLoadingMsg(null);
                                            reloadLocalData(autoSelectSection, true);
                                            setEditedFolderI(null);
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            }
                            { (editedFileI !== null && user.files[editedFileI].id !== 0) &&
                                <div className="options">
                                    <button
                                        onClick={ async () => {
                                            InternalTracker.trackEvent("File Deleted");
                                            setLoadingMsg("Deleting File...")
                                            UserAPI.deleteFile(editedFile.id).then(data => {
                                                (window as any).toast("File Deleted", "success");
                                                setEditedFileI(null);
                                                reloadLocalData(autoSelectSection, true);
                                            }).catch(e => {
                                                (window as any).toast(e?.response?.data || "Failed to delete file", "error");
                                                setEditedFileI(null);
                                                reloadLocalData(autoSelectSection, true);
                                            })
                                        }}
                                        className="main delete"
                                    >
                                        <IonIcon icon={trashOutline} />
                                        Delete
                                    </button>
                                    <button 
                                        className="main save-progress-btn" 
                                        id="edit-file-btn"
                                        onClick={ async () => {
                                            InternalTracker.trackEvent("File Viewed");
                                            const now = new Date().getTime();
                                            if (editedFile.accessLink) {
                                                if (editedFile.accessLinkExpiry < now) {
                                                    window.open(editedFile.accessLink);
                                                } else {
                                                    reloadLocalData(autoSelectSection, true, (newUser) => {
                                                        window.open(newUser.files[editedFileI].accessLink);
                                                    })
                                                }
                                            }
                                        }}
                                    >
                                        <IonIcon icon={openOutline} />
                                        View
                                    </button>
                                    <button 
                                        className="main save-progress-btn" 
                                        onClick={ async () => {
                                            InternalTracker.trackEvent("File Saved");
                                            setLoadingMsg("Updating File Details...")
                                            let res = await UserAPI.updateFileMetadata(editedFile).catch(e => {
                                                (window as any).toast(e?.response?.data || "Failed to update file", "error");
                                            })
                                            setLoadingMsg(null);
                                            reloadLocalData(autoSelectSection, true);
                                            setEditedFileI(null);
                                        }}
                                    >
                                        <IonIcon icon={saveOutline} />
                                        Save
                                    </button>
                                </div>
                            }
                            { (editedFileI === null && editedFolderI === null && !orderingFiles) &&
                                <div className="options">
                                    { (user.files.length > 1) &&
                                        <button
                                            onClick={() => {
                                                if (user.files.length > 1)
                                                    setOrderingFiles(true)
                                            }}
                                            className="main"
                                        >
                                            Display Order
                                        </button>
                                    }
                                    <button 
                                        id='add-file-btn'
                                        className={"main no-auto-save save-progress-btn" + ((user && user.files && user.files.length === 0 && user.folders && user.folders.length === 0 && !editedFile && !editedFolder) ? " pulse no-size" : "")}
                                        onClick={() => {
                                            clear();
                                            let files = user.files;
                                            let lastDisplayOrder = [0].concat(files.map(item => item.displayOrder)).sort((a,b)=>a-b)[files.length];
                                            let newFile: UserFile = {
                                                id: 0,
                                                access: localStorage.getItem("lastFileAccessType") ? parseInt(localStorage.getItem("lastFileAccessType") || "0") : FileAccess.Public,
                                                displayOrder: lastDisplayOrder+1
                                            }
                                            if (selectedFolderId) {
                                                newFile.folderId = selectedFolderId;
                                            }
                                            files.unshift(newFile)
                                            setEditedFileI(0);
                                            setFileCategorySuggestionsQuery("");
                                            setUser({ ...user, files: files });
                                        }}
                                    >
                                        Add Content
                                    </button>
                                    <button 
                                        id='add-folder-btn'
                                        className="main no-auto-save save-progress-btn" 
                                        onClick={() => {
                                            clear();
                                            let folders = user.folders || [];
                                            folders.unshift({
                                                id: 0,
                                                access: localStorage.getItem("lastFileAccessType") ? parseInt(localStorage.getItem("lastFileAccessType") || "0") : FileAccess.Public,
                                            })
                                            setEditedFolderI(0);
                                            setUser({ ...user, folders: folders });
                                        }}
                                    >
                                        Create Folder
                                    </button>
                                </div>
                            }
                            { orderingFiles &&
                                <div className="options">
                                    <button 
                                        className="main save-progress-btn" 
                                        onClick={() => {
                                            InternalTracker.trackEvent("File Order Saved");
                                            setOrderingFiles(false);
                                            UserAPI.updateFilesOrder(user.files.map(item => {
                                                return {
                                                    id: item.id,
                                                    displayOrder: item.displayOrder
                                                }
                                            })).then(() => {
                                                (window as any).toast("Order Updated", "success");
                                                reloadLocalData(autoSelectSection, true);
                                            }).catch(() => {
                                                (window as any).toast("Failed to update order", "error")
                                                reloadLocalData(autoSelectSection, true);
                                            })
                                        }}
                                    >
                                        Save Order
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

            { (currentSelection && currentSelection.id === "qualifications") &&
                <div className="swipeable-drawer-body" data-visible={open}>
                    <div className='drawer-wrapper'>
                        {SECTION_HEADER}
                        <div className="open-task">
                            { (user && user.qualifications && user.qualifications.length === 0) &&
                                <div className='no-items'>
                                    <div>
                                        <IonIcon icon={informationCircleOutline} />
                                        <p>No qualifications added yet</p>
                                    </div>
                                </div>
                            }
                            { (user && user.qualifications) &&
                                <div className="qualifications">
                                    { user.qualifications.map((qualification, i) => {
                                        const editing = editedQualificationI === i;
                                        let PFormatted = "";
                                        let H3Formatted = "Empty Education";
                                        if (qualification.field) {
                                            H3Formatted = qualification.field;
                                            if (qualification.school) {
                                                H3Formatted += " (" + qualification.school + ")"
                                            }
                                        }
                                        if (qualification.degree || qualification.grade || qualification.endDate || qualification.description) {
                                            if (qualification.degree) PFormatted += " · " + qualification.degree;
                                            if (qualification.grade) PFormatted += " · " + qualification.grade;
                                            if (qualification.endDate && qualification.endDate.split("-").length === 2) {
                                                const month = parseInt(qualification.endDate.split("-")[1]);
                                                const shortMonth = month === 1 ? "Jan" : month === 2 ? "Feb" : month === 3 ? "Mar" : month === 4 ? "Apr" : month === 5 ? "May" : month === 6 ? "Jun" : month === 7 ? "Jul" : month === 8 ? "Aug" : month === 9 ? "Sep" : month === 10 ? "Oct" : month === 11 ? "Nov" : "Dec"
                                                PFormatted += " · " + shortMonth + " " + qualification.endDate.split("-")[0];
                                            }
                                            if (qualification.description) PFormatted += " · " + qualification.description;
                                            PFormatted = PFormatted.substr(2, PFormatted.length-1)
                                        }
                                        return (
                                            <div className="qualification">
                                                {/* <div className="qualification-head">
                                                    <h2>{qualification.school || qualification.field || "New Education"}</h2>
                                                </div> */}
                                                { (!editing) &&
                                                    <div className="details-preview">
                                                        { H3Formatted && <h3>{H3Formatted}</h3> }
                                                        { PFormatted && <p>{PFormatted}</p> }
                                                    </div>
                                                }
                                                { (editing) &&
                                                    <div className="details">
                                                        {[
                                                            { field: "school", suggestions: "SCHOOLS", label: "School / University *", mandatory: true },
                                                            { field: "field", suggestions: "FIELDS", label: "Field / Subject *", mandatory: true },
                                                            { field: "grade", suggestions: "GRADES", label: "Grade" },
                                                            { field: "degree", suggestions: "DEGREES", label: "Degree" },
                                                            { field: "date", label: "Award Date" },
                                                            { field: "description", label: "Description" },
                                                        ].map(qualificationField => { 
                                                            return (
                                                                <div className="field">
                                                                    <div className="field-head">
                                                                        { (qualificationField.field === "school" || qualificationField.field === "date" || qualificationField.field === "grade" || qualificationField.field === "field" || qualificationField.field === "degree" || qualificationField.field === "description") &&
                                                                            <h3>{qualificationField.label}</h3>
                                                                        }
                                                                        { (qualificationField.field === "school" || qualificationField.field === "grade" || qualificationField.field === "field" || qualificationField.field === "degree" || qualificationField.field === "description") &&
                                                                            <IonInput
                                                                                placeholder={qualificationField.label} 
                                                                                id={i + "-" + qualificationField.field}
                                                                                value={qualification[qualificationField.field]} 
                                                                                onIonInput={(e) => {
                                                                                    const newValue: string = qualificationField.field === "school" ? Utilities.capitalizeEachFirstLetter((e.target as HTMLIonInputElement).value.toString()) : Utilities.capitalizeFirstLetter((e.target as HTMLIonInputElement).value.toString())
                                                                                    updateQualification(user, i, qualificationField.field, newValue);
                                                                                    setQualificationSuggestionQuery(newValue);
                                                                                }}
                                                                                tabIndex={6}
                                                                                onFocus={() => {
                                                                                    setTimeout(() => {
                                                                                        setFocusedField(qualificationField.field);
                                                                                    }, 450)
                                                                                }}
                                                                                onBlur={() => {
                                                                                    // setTimeout(() => {
                                                                                    //     setFocusedField(null);
                                                                                    //     setQualificationSuggestionQuery("");
                                                                                    // }, 250)
                                                                                }}
                                                                            />
                                                                        }
                                                                        { (qualificationField.field === "date") &&
                                                                            <div className='ion-input-group'>
                                                                                <IonInput style={{ width: 'calc(50% - 0px)' }} onIonInput={(e) => { updateQualification(user, i, "endMonth", (e.target as HTMLIonInputElement).value.toString()); }}  placeholder="MM" value={qualification.endMonth} />
                                                                                <IonInput style={{ width: 'calc(50% - 0px)', margin: '0 0 0 8px' }} onIonInput={(e) => { updateQualification(user, i, "endYear", (e.target as HTMLIonInputElement).value.toString()); }} placeholder="YYYY" value={qualification.endYear} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    { (qualificationField.suggestions && focusedField === qualificationField.field) &&
                                                                        <div
                                                                            className="section-content-2"
                                                                            style={{
                                                                                padding: "0 0 0 0",
                                                                                marginBottom: -4
                                                                            }}
                                                                        >
                                                                            <div className="suggestions">
                                                                                { SUGGESTIONS[qualificationField.suggestions].map(item => {
                                                                                    if (item.indexOf(qualificationSuggestionQuery) === -1) return null;
                                                                                    return (
                                                                                        <div key={item} onClick={() => {
                                                                                            updateQualification(user, i, qualificationField.field, item);
                                                                                            setTimeout(() => {
                                                                                                setFocusedField(null);
                                                                                                setQualificationSuggestionQuery("");
                                                                                            }, 200)
                                                                                        }}>
                                                                                            {item}
                                                                                        </div>
                                                                                    )
                                                                                }) }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                                <div className='qualification-head'>
                                                    <div className="options">
                                                        { (!editing) &&
                                                            <button 
                                                                onClick={() => {
                                                                    InternalTracker.trackEvent("Qualification Removed");
                                                                    let newQualifications = user.qualifications || [];
                                                                    newQualifications.splice(i, 1);
                                                                    setUser({ ...user, qualifications: newQualifications });
                                                                }}
                                                                className="delete"
                                                            >
                                                                Delete
                                                            </button>
                                                        }
                                                        { (editing) &&
                                                            <button 
                                                                onClick={() => {
                                                                    InternalTracker.trackEvent("Qualification Added");
                                                                    let newQualifications = user.qualifications || [];
                                                                    let item = newQualifications[i];

                                                                    if ((item.startYear && item.startMonth) || (item.endYear && item.endMonth)) {
                                                                        if (item.endYear && item.endMonth) {
                                                                            if (!parseInt(item.endYear) || !parseInt(item.endMonth) || parseInt(item.endMonth) < 1 || parseInt(item.endMonth) > 12) {
                                                                                
                                                                            } else {
                                                                                let end = new Date();
                                                                                end.setFullYear(parseInt(item.endYear));
                                                                                end.setMonth(parseInt(item.endMonth+1), 15);
                                                                                if (end) {
                                                                                    item.endDate = item.endYear + "-" + item.endMonth;
                                                                                    item.endDateTimestamp = end.getTime();
                                                                                }
                                                                            }
                                                                        }

                                                                        newQualifications[i] = item;

                                                                        setUser({
                                                                            ...user,
                                                                            qualifications: newQualifications
                                                                        });
                                                                    }

                                                                    setEditedQualificationI(null)
                                                                }}
                                                                className="main"
                                                            >
                                                                {/* <IonIcon icon={checkmarkCircle} /> */}
                                                                Finish Editing
                                                            </button>
                                                        }
                                                        { (!editing) &&
                                                            <button 
                                                                onClick={() => {
                                                                    setEditedQualificationI(i)
                                                                }}
                                                                className="main"
                                                            >
                                                                {/* <IonIcon icon={createSharp} /> */}
                                                                Edit
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) }
                                </div>
                            }
                            <div className="options">
                                <button
                                    onClick={() => {
                                        let newQualifications = user.qualifications || [];
                                        newQualifications.push({
                                            id: 0,
                                            school: "",
                                            field: "",
                                            degree: "",
                                            startDate: "",
                                            endDate: "",
                                            startYear: "",
                                            startMonth: "",
                                            endYear: "",
                                            endMonth: "",
                                            startDateTimestamp: 0,
                                            endDateTimestamp: 0,
                                            grade: "",
                                            description: ""
                                        });
                                        setUser({ ...user, qualifications: newQualifications });
                                        setEditedQualificationI(newQualifications.length-1)
                                    }}
                                    id="add-new-qualification-btn"
                                    className={"main" + (user && user.qualifications && user.qualifications.length === 0 ? " pulse no-size" : "")}
                                >
                                    Create New
                                </button>
                                <button className="main save-progress-btn" onClick={() => {

                                    let error = "";

                                    let preparedData = user.qualifications.map(item => {
                                        if (item.endYear && item.endMonth) {
                                            console.log(!parseInt(item.endYear), !parseInt(item.endMonth), parseInt(item.endMonth) < 1, parseInt(item.endMonth) > 12, item.endMonth)
                                            if ( !parseInt(item.endYear) || !parseInt(item.endMonth) || parseInt(item.endMonth) < 1 || parseInt(item.endMonth) > 12) {
                                                error = "Invalid Date";
                                            }
                                            let end = new Date();
                                            end.setFullYear(parseInt(item.endYear));
                                            end.setMonth(parseInt(item.endMonth)+1, 15);
                                            item.endDate = item.endYear + "-" + item.endMonth;
                                            item.endDateTimestamp = end.getTime();
                                        }

                                        if (!item.school || !item.field) {
                                            error = "School / University, and Field / Subject is required for all fields";
                                        }
                                        
                                        return item;
                                    })

                                    if (error) {
                                        (window as any).toast(error, "error")
                                        return;
                                    }

                                    setLoadingMsg("Saving Settings...");

                                    UserAPI.update({
                                        qualifications: preparedData
                                    } as UserDTO).then(res => {
                                        InternalTracker.trackEvent("Qualifications Updated");
                                        reloadLocalData(autoSelectSection);
                                        setFocusedField(null)
                                        setEditedQualificationI(null);
                                    }).catch(e => {
                                        console.error(e);
                                        setLoadingMsg(null);
                                        (window as any).toast("Failed to update settings", "error")
                                    })

                                }}>Save All</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            
            { (currentSelection && currentSelection.id === "postalcode") &&
                <div className="swipeable-drawer-body" data-visible={open}>
                    <div className='drawer-wrapper'>
                        {SECTION_HEADER}
                        <div className="open-task">
                            {/* <IonList> */}
                                {/* { autoSelectSection && window.location.href.indexOf("/settings") === -1 && */}
                                    <IonItem lines="none" style={{ marginBottom: 10 }}>
                                        <IonLabel >Zip / Postcode</IonLabel>
                                        <IonInput
                                            placeholder="Zip / Postcode" 
                                            id="zip"
                                            style={{ textAlign: 'right' }}
                                            value={user ? (user.postalCode ? user.postalCode.toUpperCase() : "") : ""} 
                                            onIonInput={(e) => { setUser({...user, postalCode: (e.target as HTMLIonInputElement).value.toString().toUpperCase() }) }  } 
                                        />
                                    </IonItem>
                                {/* } */}
                                <IonItem 
                                    lines="none" 
                                    onClick={() => {
                                        setHideDrawerForIonComponents(true);
                                    }}
                                    style={{
                                        marginBottom: 15
                                    }}
                                >
                                    {/* { autoSelectSection && window.location.href.indexOf("/contacts") !== -1 && */}
                                        <IonLabel className="ion-text-wrap" >Maximum Distance you are willing to travel for work</IonLabel>
                                    {/* } */}
                                    <IonSelect
                                        onIonChange={ async (e) => { 
                                            // @ts-ignore
                                            setUser({...user, maxDistance: e.target.value })
                                            setHideDrawerForIonComponents(false);
                                        }}
                                        value={user ? (user.maxDistance || 0) : 0}
                                    >
                                        { [0, 5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 120, 140, 160, 180, 200, 250, 300].map((miles, i) => {
                                            return (<IonSelectOption key={miles} value={miles}>{ miles ? miles + "miles - " + miles*1.6 + "km" : "Any Distance" }</IonSelectOption>)
                                        }) }
                                    </IonSelect>
                                </IonItem>
                            {/* </IonList> */}
                            <button className="main save-progress-btn" onClick={() => {

                                setLoadingMsg("Saving Settings...");

                                UserAPI.update({
                                    postalCode: user.postalCode,
                                    maxDistance: user.maxDistance,
                                } as UserDTO).then(res => {
                                    reloadLocalData(autoSelectSection);
                                    InternalTracker.trackEvent("Location and Max Distance Updated");
                                }).catch(e => {
                                    console.error(e);
                                    setLoadingMsg(null);
                                    (window as any).toast("Failed to update settings", "error")
                                })

                            }}>Save</button>
                        </div>
                    </div>
                </div>
            }

            { (currentSelection && currentSelection.id === "smsnotifications") &&
                <div className="swipeable-drawer-body" data-visible={open}>
                    <div className='drawer-wrapper'>
                        {SECTION_HEADER}
                        <div className="open-task">
                            { (currentSMSSetupStep === 1) &&
                                <div>
                                    {/* <h3>Enter your mobile number for instant offer alerts & confirmations even without internet.</h3> */}
                                    <PhoneInput
                                        preferredCountries={["US", "UK"]}
                                        enableLongNumbers={true}
                                        value={user ? (user.phoneNumber === undefined ? "+44" : !user.phoneNumber ? "" : user.phoneNumber) : "+44"}
                                        onChange={(phone) => {
                                            setUser({...user, phoneNumber: phone })
                                        }}
                                    />
                                    <button className="main save-progress-btn no-auto-save" onClick={() => {
                                        sendOtpCode(user.phoneNumber);
                                        InternalTracker.trackEvent("SMS Notifications Setup Started");
                                    }}>Verify Number</button>
                                </div>
                            }
                            { (currentSMSSetupStep === 2) &&
                                <div>
                                    <h3>Please enter the 6 digit code we sent to +{user.phoneNumber} to verify your number.</h3>
                                    <OTPInput 
                                        value={otpCode} 
                                        onChange={(code) => {
                                            setOtpCode(code);
                                            if (code.length === 6) {
                                                verifyOtpCode(user.phoneNumber, code, autoSelectSection);
                                            }
                                        }}
                                        autoFocus={true}
                                        OTPLength={6}
                                        otpType="number"
                                        style={{
                                            justifyContent: 'space-between',
                                        }}
                                    />
                                    <ResendOTP  
                                        maxTime={30} 
                                        renderTime={() => <span></span>}
                                        renderButton={(props) => <button onClick={() => {
                                            if (props.remainingTime)
                                                return null;
                                                
                                            sendOtpCode(user.phoneNumber);
                                        }}>Resend Code { (props.remainingTime) ? (" (wait " + props.remainingTime + " seconds)") : "" }</button>}
                                    />
                                    <button className="main save-progress-btn" onClick={() => {
                                        setCurrentSMSSetupStep(1);
                                    }}>Wrong number?</button>
                                </div>
                            }
                            { (currentSMSSetupStep === 3) &&
                            <div>
                                    {/* <h3>Your phone number is verified, and ready to receive notifications</h3>  */}
                                    <IonItem>
                                        <IonLabel>SMS Notifications</IonLabel>
                                        <IonToggle 
                                            slot="end" 
                                            checked={user.smsNotifications} 
                                            onIonChange={() => {
                                                InternalTracker.trackEvent((user.smsNotifications ? "Disabled" : "Enabled") + " SMS Notifications");

                                                UserAPI.toggleSMSNotifications(!user.smsNotifications).then(res => {
                                                    (window as any).toast("Notification preferences updated", "success")
                                                }).catch(e => {
                                                    (window as any).toast("Failed to update preferences", "error")
                                                })
                                            }}></IonToggle>
                                    </IonItem>
                                    <button className="main save-progress-btn" onClick={() => {
                                        setCurrentSMSSetupStep(1);
                                    }}>Change Number</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

            { (currentSelection && currentSelection.id === "image") &&
                <div className="swipeable-drawer-body" data-visible={open}>
                    <div className='drawer-wrapper'>
                        {SECTION_HEADER}
                            <div className="open-task">
                            <div className="profile-img">
                                <div
                                    className='info-box'
                                    style={{
                                        marginBottom: 15
                                    }}
                                >
                                    <IonIcon icon={informationCircle} />
                                    <p>Close up of your head and top of your shoulders so that your face takes up 70–80% of a sharp and clear photograph.</p>
                                </div>
                                <img src={UserAPI.getProfilePicture(user.id, (window as any).pictureVersions)} />
                            </div>
                            <button className="main" onClick={() => {
                                const element = document.getElementById("image-upload-input");
                                if (element) {
                                    element.click();
                                }
                            }}>Select or Take Photo</button>
                            <input id="image-upload-input" style={{ display: 'none' }} type='file' accept="image/png, image/jpeg"  onChange={ async (e) => {

                                setLoadingMsg("Uploading Image");

                                const files = Array.from(e.target.files);
                                const resized: File | void = await Utilities.resizeAndRotateImage(files[0] as Blob).catch(e => {
                                    (window as any).toast("Invalid image", "error")
                                    setLoadingMsg(null);
                                });

                                if (resized) {
                                    UserAPI
                                        .uploadProfilePicture(resized, user.id)
                                        .then(data => {

                                            UserAPI.update({
                                                profilePhotoUploaded: true
                                            } as UserDTO).catch(e => {
                                                console.error(e);
                                            })

                                            reloadLocalData(autoSelectSection);
                                            if (onImageUpdate)
                                                onImageUpdate();
                                            InternalTracker.trackEvent("Profile Image Updated");
                                        }).catch(e => {
                                            console.error(e)
                                            setLoadingMsg(null);
                                            (window as any).toast("Failed to upload profile image, make sure it is less than 10MB and that you have internet connection.", "error")
                                        })
                                }

                                }} 
                            />
                        </div>
                    </div>
                </div>
            }

            <IonLoading
                isOpen={loadingMsg !== null}
                onDidDismiss={() => setLoadingMsg(null)}
                message={loadingMsg}
                duration={3000000}
            />
            { confetti &&
                <Confetti
                    width={document.documentElement.clientWidth}
                    recycle={false}
                    numberOfPieces={350}
                    onConfettiComplete={() => {
                        setConfetti(false)
                    }}
                    height={document.documentElement.clientHeight}
                />
            }
            <IonToast
                isOpen={successToast !== null}
                color="success"
                onDidDismiss={() => setSuccessToast(null)}
                message={successToast}
                position="top"
                duration={2500}
                buttons={[ { text: 'Hide', role: 'cancel' } ]}
            />
            <IonToast
                isOpen={errorToast !== null}
                color="danger"
                onDidDismiss={() => setErrorToast(null)}
                message={errorToast}
                position="top"
                duration={2500}
                buttons={[ { text: 'Hide', role: 'cancel' } ]}
            />
            { (keyboardOpen) &&
                <div className="keyboard-hide">
                    <IonIcon icon={caretDownCircle} />
                </div>
            }
        </Drawer>
    );
    
};

export default ProfileProgress;
